.toast-notification {
	&.success {
		border-color: $success;
		color: darken($success,20%);
	}
	&.warning {
		border-color: $warning;
		color: darken($warning,25%);
	}
	&.error {
		border-color: $error;
		color: darken($error,20%);
	}
	&.info {
		border-color: $info;
		color: darken($info,20%);
	}
}