@import '../../../styles/constants.scss';
@import '../../../styles/_variables.scss';

.order-header {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .order-header-title {
        font-weight: 600;
    }
}

.order-header-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    padding-bottom: 6px;
    padding-top: 6px;
}

.order-header-details {
    display: grid;
    padding: 0 16px;
    grid-template-columns: 0.6fr 0.4fr 0.6fr 0.4fr 1fr;
    grid-gap: 5px;
    background-color: white;

    .order-header-details-arrow {
        margin: auto;
        
        svg {
            width: 20px;
            height: 20px;
        }
    }

    .order-header-details-location {
        background-color: #f5f5f5;
        border-radius: 2px;
        padding: 10px;
        padding-right: 20px;
        margin: 16px 0;
    }

    .order-header-details-eta {
        padding: 10px;
        padding-left: 0px;
        margin: 16px 0;

        .order-header-details-eta-date-time {
            display: flex;
            align-items: baseline;

            .read-only-time {
                margin-left: 5px;
            }
        }

        .order-header-details-eta-risk-level {
            display: flex;

            svg {
                width: 20px;
                height: 20px;
                margin-top: -2px;
            }
        }

        .read-only-risk-level {
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;

            &.at-risk {
                color: $status-atrisk-text;
            }

            &.early {
                color: $status-early-text;
            }

            &.on-time {
                color: $status-ontime-text;
            }

            &.late {
                color: $status-late-text;
            }
        }
    }

    @media (max-width: 1440px) {
        grid-template-columns: 0.8fr 0.2fr 0.8fr 0.2fr 1fr;
    }
}

@media (max-width: 40em) {
    .order-header-container {
        grid-template-columns: none;
        grid-gap: 0;
        padding-bottom: 0;
        padding-top: 0;
        text-align: center;
    }

    .order-header-details {
        grid-template-columns: none;
        grid-gap: 0;

        .order-header-details-arrow {
            transform: rotate(90deg);
        }

        .order-header-details-location, .order-header-details-eta {
            width: 67%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 16px;
        }

        .order-header-details-eta {
            margin-top: 0px;
            padding-top: 0px;
            padding-left: 10px;
        }
    }
}

.btn-sm-add-height {
  padding: 2px;
}