.disruptions-page {
    display: flex;
    width: 100%;
    height: 100%;
    
    .disruptions-menu {
      min-width: 250px;
      max-width: 250px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 70px;
      overflow-y: auto;
    }
    .tracker-map {
      height: 100%;
      position: relative;
    }
    .border-bottom {
      border-bottom: 2px solid $outline-gray;
      margin: 10px 0px 10px 0px;
    }
    .disruptions-header {
      height: fit-content;
      padding-bottom: 2rem;
    }
    .disruptions-map {
      order: 2;
      background: $map-background;
      width: 100%;
      
      #myMap {
        width: 100%;
      }
    }
    .no-padding {
      padding: 0px;
    }
    .actions {
      float: right;
    }
  }

  .incidents-box {
    border: 2px solid $outline-gray;
    margin-bottom: 8px;
    min-height: 60px;

    .incidents-icons {
      cursor: pointer;
      margin-left: .5rem;
      margin-bottom: .5rem;
    }
  }

  .incidents-text {
    margin-left: .3rem;
    margin-right: .3rem;
    margin-top: .3rem;
    margin-bottom: .3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: inherit;
  }

  .incidents-edit {
    float: right;
    margin-right: .2rem;
    margin-top: .2rem;
  }

  #incidentSaveButton {
    width: 40%;
    margin-right: 4px; 
    margin-left: 15px; 
  }
  
  #incidentSaveButton:disabled {
    color: #000;
  }
  
  #incidentCancelButton {
    width: 40%; 
  }

  @media (max-width: $break-md) {
    .map-split {
      display: none;
    }
  }

.incidents-label {
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

.create-incident-container {

  .react-datepicker-wrapper .input-group-btn .btn {
    z-index: 1;
  }
  .dateRange {
    margin-top: 15px; 
    label {
      margin-top: 8px;
      margin-bottom: 8px; 
    }
  }
  .custom-select {
		min-width: 100px;
		display: inline-block;
		margin-right: 10px;
		label {
			margin-bottom: 5px;
		}
	}
}
