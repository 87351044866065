.progress-bar-outline {
  position: relative;
  display: -ms-flexbox;
  display: inline-flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: $white;
  border: 1px solid $black;
  padding: 0px;
  box-shadow: none;
}

.mainScoreCardHeader {
  font-weight: bold;
  text-transform: uppercase;
}

.scoreCardHeaders {
  font-weight: bold;
  margin-bottom: 0.3rem;
  margin-top: 0.5rem;
}

.scorecard-headers {
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 1.5rem; 
}

.scoreCardCounts {
  font-weight: 600;
}

.scorecardPercentages {
  position: absolute;
  margin-left: 0.5rem;
  margin-top: 0.1rem;
}

.carrierRollupTitles {
  font-weight: 700;
  font-size: 15px;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2.5rem; 
}

.carrierRollupNumbers {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 1175px) {
  .onTimePickup{
    padding-left: 30px; 
  }
  .carrierRollUp {
    font-size: 12px; 
  }
  .scoreCardHeaders {
    font-size: 14px; 
  }
  .scorecardPercentages {
    font-size: 11px; 
  }
}