@import '../../../styles/constants.scss';

.sideNav-container {
  position: absolute;
  height: 100%;
  transition: max-width .3s cubic-bezier(0.25, 0.1, 0.24, 1);
}

.expanded {
  position: absolute;
  height: 100%;
  max-width: 240px;
}

@media (min-width: $break-md) {
  .content {
    display: flex;
    flex-direction: column;
    margin-left: 64px;
    transition: margin-left .3s cubic-bezier(0.25, 0.1, 0.24, 1);
    overflow: auto;
    height: 100vh;
    max-height: 100%;
  }
  .sideNav-container.collapsed {
    position: absolute;
    height: 100%;
    max-width: 64px;
  }
}

@media (max-width: $break-md - 1) {
  .sideNav-container.collapsed {
    position: absolute;
    left: -70px;
    max-width: 64px;
    height: 100%;
  }
  .expanded {
    position: absolute;
    height: 100%;
    max-width: 240px;
    z-index: 10000;
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    transition: margin-left 0.3s cubic-bezier(0.25, 0.1, 0.24, 1);
    overflow: auto;
    height: 100vh;
    max-height: 100%;
    width: 100%;
  }
  .col-xs-10.col-sm-4.col-md-offset-8 {
    padding-right: 0;
    margin-left: 33%;
    .icon, img {
      height: 21px;
      width: 21px;
    }
  }
}

.pushed {
  margin-left: 240px;
}
.sidenav---sidenav---_2tBP {
  background: $dark-gray;
  color: $white;
  box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.25);
  transition: min-width .3s ease;
  position: relative;
  height: 100%;
}

.sidenav---navtext---1AE_f {
  color: $white!important;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: .05em;
}

 .sidenav---highlighted---oUx9u {
   .sidenav---navitem---9uL5T {
     background: $black;
     border-left: 4px solid $navisphere-orange;
   }
}

.sidenav---navitem---9uL5T {
  cursor: pointer!important;
  border-left: 4px solid rgba(255, 255, 255, 0);
  background-color: $dark-gray;
  transition: background-color 250ms;
}

.sidenav---navitem---9uL5T:hover {
  cursor: pointer!important;
  background-color: $nav-black;
}

.sidenav---navicon---3gCRo {
  .icon {
    margin-right: 0.7em;
  }
}

.mobile-toggle-container {
  height: 44px;
}

.col-xs-2.col-sm-4.hidden-md.hidden-lg {
  top: 8px;
  button.clear {
    position: fixed;
    z-index: 9999;
    background: transparent;
    border: none;
    top: 8px;
    .icon {
      height: 20px;
      width: 20px;
      fill: #333333;
    }
  }
  button.clear:focus {
    outline: none;
  }
}


.overlay {
  position: absolute;
  opacity: .5;
  background-color: $black;
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.24, 1)
}

.sideNav-expand-toggle svg {
  fill: white!important;
}

.sideNav-expand-toggle.mobile-expanded svg {
  opacity: 0;
}
