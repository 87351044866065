.healthChart {
  margin-top: 30px; 
}

.oceanLineGraph, .groundLineGraph, .airLineGraph, .parcelLineGraph {
  margin-top: 30px;
}

.lineGraphDateRange {
  text-align: center;
}

.landingPageTitle {
  padding: 20px 0px 20px 30px;
  h1 {
    font-weight: bold;
  }
}

.shipmentsTable {
  padding: 0px 50px 0px 30px;
}

.shipment-count-callout {
  display: block;
	background-color: lighten($outline-gray, 9%);
	color: $medium-gray;
	text-align: left;
	margin-bottom: 10px;
	align-items: center;
	padding: 8px;
	position:relative;
	transition: background-color $transition-default;
	&:hover {
		background-color: lighten($outline-gray, 5%);
	}
	> span {
		display: inline-block;
	}
	.shipment-count {
		font-size: 18px;
		font-weight: 500;
		line-height: 14px;
	}
	&.group-callout {
		h4.uppercase {
			margin-bottom: 5px;
		}
	}
}

.incident-overview {
	.card {
		height: 100%;
	}
	.incident-current {
		margin-bottom: 10px;
		.read-only {
			margin-bottom: 8px;
		}
	}
	.incident-forcast {
		img {
			width: 100%;
		}
	}
}

.status-headline {
	h4 {
		&:before {
			content: '';
			height: 12px;
			width: 12px;
			display: inline-block;
			border-radius: 15px;
			margin-right: 5px;
			background-color: $status-atrisk;
		}
	}
	&.early {
		h4 {
			&:before {
				background-color: $status-early;
			}
		}
	}
	&.ontime {
		h4 {
			&:before {
				background-color: $status-ontime;
			}
		}
	}
	&.atrisk {
		h4 {
			&:before {
				background-color: $status-atrisk;
			}
		}
	}
	&.late {
		h4 {
			&:before {
				background-color: $status-late;
			}
		}
	}
}

h4.uppercase {
  margin-bottom: 0px;
  &.small {
    font-size: 10px;
    color: $light-gray;
  }
}

.full-width {
	width: 100%;
}

.medium-value {
	font-weight: 500;
	font-size: 30px;
}

.card-content {
  overflow-y:auto;
  max-height: 225px;
}

.newsExceptions {
  margin-right: 30px;
}

.covid {
	background-color: #fcc;
	padding: 16px;
	color: #333;
}

.covid-warn {
	text-transform: capitalize;
	font-weight: 500;
	padding-left: .5em;
}

.covid-dismiss {
	float: right;
	cursor: pointer;
	font-size: smaller;
}

.embed-container {
	height: 600px;
	width: 100%;
}

.overlay-font {
	font-size: 60%;
}

.no-powerbi-items-message {
	margin: 0 15px 25px 15px;
}