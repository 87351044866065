.shipment-tracker {
  display: flex;
  width: 100%;
  height: 100%;

  .tracker-map {
    height: 100%;
    position: relative;
  }

  .border-bottom {
    border-bottom: 1px solid $outline-gray;
    margin: 10px 0px 10px 0px;
  }

  .map-split {
    order: 2;
    background: $map-background;
    width: 100%;
    max-width: 700px;

    #myMap {
      width: 100%;
    }
  }

  .no-padding {
    padding: 0px;
  }

  .load-header {
    width: 100%;
  }

  .load-numbers {
    margin-bottom: 15px;
  }

  .load-content {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .details-container {
    padding: 15px 0px 0px;
    display: block;

    h1 {
      margin-top: 0px;
    }

    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    position: initial;
  }

  .actions {
    float: left;
  }

  .load-stops {
    display: grid;
    grid-template-columns: 15% 80% 5%;
    grid-template-areas:
        "details dates  options"
        "orders orders orders";
    border-bottom: 1px solid #f5f5f5;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .load-stop-identifier {
    margin-right: 15px;
    grid-area: details;
  }

  .load-stop-options {
    grid-area: options;
  }

  .load-stop-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .load-stop-dates {
    grid-area: dates;
  }

  .load-stop-orders, .load-orders {
    grid-area: orders;
    background-color: $tab-content-gray;
    border-radius: 5px;
    padding: 5px 10px;
    max-width: max-content;

    .order-numbers {
      display: flex;
      align-items: center;
    }

    .btn-icon {
      padding: 0 0 0 5px;
    }
  }
  
  .load-stop-date {
    flex-basis: 16.6%
  }
}

@media (max-width: $break-lg) {
  .shipment-tracker {
    .load-stop-date {
      flex-basis: 33.3%;
    }

     .load-stops {
      grid-template-columns: 25% 70% 5%;
    };
  }
}

@media (max-width: $break-md) {
  .map-split {
    display: none;
  }
}

.shipment-notes {
  margin-bottom: 30px;

  .note {
    margin-right: 15px;
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
  }
}

@media (max-width: $break-sm) {
  .shipment-tracker {
    flex-direction: column;

    .actions {
      float: initial;
    }
  }
}

.card {
  &.load-details {
    &:before {
      height: 100%;
      width: 5px;
      display: block;
      background-color: $outline-gray;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
    }

    .load-number {
      font-size: 12px;
      margin-bottom: 8px;
    }

    &.early:before {
      background-color: $status-early;
    }

    &.ontime:before {
      background-color: $status-ontime;
    }

    &.atrisk:before {
      background-color: $status-atrisk;
    }

    &.late:before {
      background-color: $status-late;
    }
  }

  .load-card-open {
    padding: 5px;
    background-color: $light-content-gray;
    border-radius: 20px;
    position: absolute;
    top: 35%;
    transition: background-color 250ms ease;
    width: 30px;
    height: 30px;
    text-align: center;
    right: 10px;

    &:hover {
      background-color: darken($light-content-gray, 5%);
    }

    >svg {
      transition: all 100ms ease;
    }

    &.collapsed {
      >svg {
        transform: rotate(180deg);
      }
    }
  }

  .load-dates {
    margin-bottom: 15px;
  }
}

.panel-header {
  background-color: $light-content-gray;
  padding: 15px;
}

.add-20px-bottom-margin {
  margin-bottom: 20px;
}