$break-xs: 							415px;
$break-sm: 							768px;
$break-md: 							992px;

$dark-gray: 						#333333;
$medium-gray: 						#666666;
$light-gray: 						#999999;



$background-gray: 					#f5f5f5;
$faded-gray: 						#cccccc;
$outline-gray: 						#dddddd;
$tab-content-gray: 					#eeeeee;
$light-content-gray: 				#f9f9f9;

//Override Autostyle Grays
// $placeholder-gray:            	#adadad;
// $input-border-gray:           	#a9a9a9;
// $disabled-gray:               	#ebebe4;


$box-shadow: 					0 2px 8px 0 rgba(0,0,0,0.10);
$box-shadow-overlay: 			0 5px 15px 0 rgba(0,0,0,0.20);

$focus-white: 					0 0 6px 3px rgba($white,0.3);
$focus-black: 					0 0 6px 3px rgba($black,0.8);
$focus-default: 				0 0 6px 0 rgba(74,144,226,0.50);

$brand-primary: 				$active-blue;
$active-blue-dark: 				#00567b;
$map-background: 				#a9c8f2;

// Status
$status-early: 					#2E99E0;
$status-ontime: 				#7EA122;
$status-atrisk: 				#D5B100;
$status-late: 					#BA4B4D;
$status-noappointment: 	#A6A6A6;

// Status text color
$status-early-text:             #0078AE;
$status-ontime-text:            #2C561B;
$status-atrisk-text:            #715808;
$status-late-text:              #83242A;

// Validations
$info: 							$status-early;
$success: 						$status-ontime;
$warning: 						$status-atrisk;
$error: 						$status-late;

// Simplify Color Variables from Autostyle
$validation-green:            	$status-ontime;
$validation-yellow:           	$status-atrisk;
$error-red:                   	$status-late;

// Transitions
$transition-default: 			250ms ease;
$transition-cubic-bezier: 		250ms cubic-bezier(.25,.1,.24,1);
$transition-quick: 				100ms ease;



$focus: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
