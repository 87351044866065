.footer {
	font-size: 11px;
	padding: 8px 0px;
	background-color: $white;
	border-top: 1px solid $outline-gray;
	z-index: 1000;
	ul {
		list-style-type: none;
		padding-left: 5px;
		display: inline;
		li {
			a {
				padding: 0px 5px;
			}
			display: inline;
			border-right: 1px solid $outline-gray;
			&:last-child {
				border-right: none;
				margin-right: 5px;
			}
			&:first-child > a {
				padding-left: 0px;
			}
		}
	}
	p {
		display: inline;
		margin-bottom: 0px;
		font-size: 11px;
		font-weight: 400;
		line-height: normal;
	}
}

.fixed-bottom {
  position: relative; 
  bottom: 0; 
  width: 100%; 
}

.fixed-position-bottom {
  position: fixed; 
  bottom: 0; 
  width: 100%; 
}

.copyright-statement {
  white-space: nowrap;
}

.footer-row {
	margin-left: 0;
}

.footer-logo {
	margin-top: 6px;
	margin-bottom: 6px;
}

.container-footer {
	flex-wrap: wrap;
	justify-content: space-between;
}

@media (max-width: 778px) {
  .site-legal {
    float: left;
  }
}