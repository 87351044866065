.global-search {
  padding: 8px 0px;
  width: 100%;
  min-width: 125px;

  input {
    outline: none;
    width: 100%;
  }

  input[type=search] {
    -webkit-appearance: textfield;
    font-family: inherit;
    font-size: 100%;
    padding-left: 2px;
    //color: transparent;
    cursor: pointer;
    width: 100%;
  }

  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button {
    display: none;
  }

  input[type=search] {
    border: 1px solid transparent;
    padding: 5px 40px 5px 5px;
    background-color: white;
    width: 100%;
    word-wrap: normal;
  }

  .global-search-close-icon {
    position: absolute;
    top: 33px;
    right: 0px;
    font-size: 20px;
    cursor: pointer;
    width: 1.8rem;
  }

  .global-search-icon {
    position: absolute;
    top: 33px;
    right: 5px;
    font-size: 20px;
    cursor: pointer;
    width: 1.8rem;
  }
}

.search-remove {
  position: absolute;
  top: 2.1rem;
  margin-top: 1rem;
  right: 2rem;
  z-index: 50;
  font-size: 20px;
}

.advanced-search {
  z-index: 1000;
  background-color: white;
  box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.25);
  position: absolute;
  border-radius: 0px;
  font-size: 12px;
  border: 1px solid $outline-gray;
  padding: 8px 8px 10px 8px;
  font-weight: 500;
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  top: 10px;

  svg {
    top: 0px;
  }
}

.space-bottom {
  margin-bottom: 1rem;
}