.split-view {
	.bm_bottomRightOverlay {
		right: initial !important;
		bottom: 30px !important;
	}

	.bm_disable_highlighting .quadrantOverride,
	#ScaleBarId {
		text-align: left !important;
	}

	.NavBar_MapTypeButtonContainerWrapper,
	.bm_LocateMeControl {
		display: none;
	}

	.MicrosoftMap .NavBar_Container {
		top: auto !important;
		bottom: 70px !important;
		right: 15px !important;
	}

	.MicrosoftMap .NavBar_Container .NavBar_Button {
		height: 35px !important;
		width: 35px !important;
	}
}

.shipment-tracker {
	.bm_bottomRightOverlay {
		right: initial !important;
		left: 30px !important;
		bottom: 30px !important;
	}

	.bm_disable_highlighting .quadrantOverride,
	#ScaleBarId {
		text-align: left !important;
	}

	.NavBar_MapTypeButtonContainerWrapper,
	.bm_LocateMeControl {
		display: none;
	}

	.MicrosoftMap .NavBar_Container {
		top: auto !important;
		bottom: 70px !important;
		right: 15px !important;
	}

	.MicrosoftMap .NavBar_Container .NavBar_Button {
		height: 35px !important;
		width: 35px !important;
	}
}

.map-only {
	.bm_bottomRightOverlay {
		right: initial !important;
		bottom: 30px !important;
	}

	.bm_disable_highlighting .quadrantOverride,
	#ScaleBarId {
		text-align: left !important;
	}

	.NavBar_MapTypeButtonContainerWrapper,
	.bm_LocateMeControl {
		display: none;
	}

	.MicrosoftMap .NavBar_Container {
		top: auto !important;
		bottom: 70px !important;
		right: 15px !important;
	}

	.MicrosoftMap .NavBar_Container .NavBar_Button {
		height: 35px !important;
		width: 35px !important;
	}
}

.disruptions-page {
	.bm_bottomRightOverlay {
		right: initial !important;
		bottom: 30px !important;
	}

	.bm_disable_highlighting .quadrantOverride,
	#ScaleBarId {
		text-align: left !important;
	}

	.NavBar_MapTypeButtonContainerWrapper,
	.bm_LocateMeControl {
		display: none;
	}

	.MicrosoftMap .NavBar_Container {
		top: auto !important;
		bottom: 70px !important;
		right: 15px !important;
	}

	.MicrosoftMap .NavBar_Container .NavBar_Button {
		height: 35px !important;
		width: 35px !important;
	}
}