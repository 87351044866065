.popover {
  border-radius: 0px;
  border: none;
  box-shadow: $box-shadow-overlay;
  padding: 0px;
  min-width: 200px;
  font-size: 12px;
  line-height: normal;
  font-family: 'Open Sans', sans-serif;
  &.bottom > .arrow {
  	border-bottom-color: transparent;
  }
  &.popover.top > .arrow {
  	border-top-color: transparent;
  	bottom: -9px;
  }
  .popover-title {
  	background-color: $white;
  	padding: 8px;
  }
  .popover-content {
  	padding: 8px;
  }
  &.small {
  	font-size: 12px;
  }
}
.popover-icon {
  > svg {
    width: .95em;
  }
}