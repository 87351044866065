.read-only {
    display: inline-block;
    margin-bottom: 10px; 
}

.support-text {
    font-size: 10px;
    display: inline-block;
}
.read-only-label,
.read-only-value,
.read-only-time {
    display: block;
}

.read-only-label {
    font-size: 12px;
    color: $medium-gray;
    &.inline {
        display: inline;
    }
}

.read-only-value {
    font-weight: 600;
    font-size: 14px;
    line-height: normal;
    overflow-wrap: break-word;
    overflow: hidden;
    &.value-small {
        font-size: 12px;
    }
}
.read-only-time {
    font-size: 12px;
    color: $medium-gray;
    &.time-small {
        font-size: 10px;
    }
}


.read-only + .read-only {
    margin-right: 15px;
}


