.downloads-page {
  display: flex;
  width: 100%;
  height: 100%;

  .border-bottom {
    border-bottom: 2px solid $outline-gray;
    margin: 10px 0px 10px 0px;
  }
  .downloads-header {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
}
