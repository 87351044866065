.btn-container-tracking {
    float: right; 
}
.lfdContainerStatus {
    display:inline-block;
}
.lfdDateField {
    display:inline-block;
    margin-left: 5em;
}
.container-headers {
    margin-bottom: 1rem;
}