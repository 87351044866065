#facility-filter-panel {
  .panel {
    box-shadow: none;
  }
	.panel-title {
    color: #808080;
    background-color: white;
    border:white;
    height: 30px;    
    padding-left: 5px;
    padding-top: 5px;
    padding-right:5px; 
  }
  .panel-title a{
     font-size: 12px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     padding: 0px;
     margin: 0px;
  }
   .panel-body {
     border: 1px solid $outline-gray;
  }

  label {
    margin: 0px;
  }
 
}
