
.performance-and-compliance-container {
    display: flex; 
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    > div {
        flex-basis: 50%;
    }
    >:last-child {
        margin-left: 15px;
    }
}

@media(max-width: 480px){
    .performance-and-compliance-container {
        flex-direction: column;
        > div {
            flex-basis: 50%;
        }
        >:last-child {
            margin-top: 15px;
            margin-left: 0px;
        }
    }
    
}