.map-popover {
	background: $white;
	box-shadow: $box-shadow-overlay;
  padding: 10px;
  width: 230px;
  position: relative;
  margin-bottom: 10px;
	&.large {
		width: 300px;
	}
	.read-only {
		max-width: 115px;
	}
	.map-popover-header {
		position: relative;
		margin-top: 5px;
		.read-only-label {
      font-size: 14px;
      font-weight: 400;
		}
  }
  .map-popover-details {
    hr {
      margin-top: 15px;
      margin-bottom: 10px;
    }
    .read-only-value {
      font-size: 14px;
      font-weight: 300;
    }
  }
	.location-info{
		margin-bottom: 10px;
	}
	.special-info {
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.btn {
		width: 100%;
	}
	svg.close {
		position: absolute;
    right: 5px;
    top: 5px;
		opacity: .5;
		font-size: 16px;
  }
  .bubble-data {
    font-weight: 600;
    font-size: 12px;
  }

  .metrics-info {
    margin-bottom: 10px;

    .last-location-update-label {
      font-size: 12px;
      color: #545658;
    }

    .last-location-update-date {
      font-size: 12px;
      color: #111112;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .metrics-summary {
      background-color: #EEEFEF;
      color: #111112;
      padding: 8px;
      border-radius: 2px;
      font-size: 12px;
    }
  }

  .metrics-value-header {
    display: flex;
    align-items: center;
  }
  
  .metrics-tooltip-content {
    visibility: hidden;
    width: 135px;
    margin-left: 15px !important;
  }
  
  .metrics-tooltip {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  
  .metrics-tooltip:hover .metrics-tooltip-content {
    visibility: visible;
  }
}

.bubble-content{
  border-top: solid #DBDBDB 1px;
  padding: 5px 5px;
  font-size: 12px;
  font-weight: normal;
}

.bubble-content-row{
  display:flex;
}

.info-button {
  background-color: #EBEBEB;
  display:flex;
  justify-content: space-between;
  align-items:center;
  margin-right:10px;
  cursor: pointer;
  min-width:85px;
  border: none;
  pointer-events: auto;
  text-align:left;
  &:hover{
    box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, .16);
  }
  &:last-child{
    margin-right:0;
  }
}

.bubble:after{
	content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  
}

.topMiddle:after {
  top: 0;
  left: 50%;
  border-bottom-color: $white;
  border-top: 0;
  margin-left: -15px;
  margin-top: -15px;
}

.bottomMiddle:after{
  bottom: 0;
  left: 50%;
  border-top-color: $white;
  border-bottom: 0;
  margin-left: -15px;
  margin-bottom: -15px;
}

.upperLeft:after {
  top: 0;
  left: 10px;
  border: 20px solid transparent;
  border-bottom-color: $white;
  border-top: 0;
  border-left: 0;
  margin-left: -10px;
  margin-top: -20px;
}

.upperRight:after {
  top: 0;
  right: 0;
  border: 20px solid transparent;
  border-bottom-color: $white;
  border-top: 0;
  border-right: 0;
  margin-left: -10px;
  margin-top: -20px;
}

.lowerLeft:after {
  bottom: 0;
  left: 10px;
  border: 20px solid transparent;
  border-top-color: $white;
  border-bottom: 0;
  border-left: 0;
  margin-left: -10px;
  margin-bottom: -20px;
}

.lowerRight:after {
  bottom: 0;
  right:0;
  border: 20px solid transparent;
  border-top-color: $white;
  border-bottom: 0;
  border-right: 0;
  margin-left: -10px;
  margin-bottom: -20px;
}

.middleLeft:after{
  left: 0;
  top: 50%;
  border-right-color: $white;
  border-left: 0;
  margin-top: -15px;
  margin-left: -15px;
}

.middleRight:after{
  right: 0;
  top: 50%;
  border-left-color: $white;
  border-right: 0;
  margin-top: -15px;
  margin-right: -15px;
}