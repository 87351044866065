.createPasswordPage{
  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;  
  }

  .homeLogo {
    margin-top: 15px; 
  }

  .header-container {
    min-height: 68px;
  }

  button {
    margin-right: 8px; 
    margin-top: 15px; 
  }

  .help-block {
    color: lightcoral; 
  }

  .invalid {
    border: .7px solid lightcoral; 
  } 

  .passwordForm, .alertForm {
    padding-top: 15px; 
    margin-left: 15px;
  } 

  h3 {
    margin-bottom: 20px;
  }

  .save-button {
    line-height: 1.2;
    display: inline-block;
    margin: 0 10px 0 0;
    padding: 10px 45px;
    box-shadow: none;
    border-radius: 0;

    font-size: 20px;
    color: #FFFFFF;
    background-color: #F0693A;
    border: solid 1px #FFFFFF;
    opacity: 1;
  }
}
