.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.external-content {
	overflow-x: hidden !important;
	height: 100%;
}

.external-header {
  margin-right: auto;
  margin-left: auto;
	padding-left: 10px;
	padding-bottom: 10px;
	padding-top: 10px;
}

.site-content {
	overflow-x: hidden !important;
	position: relative;
	left: 0;
}
// Page Wrapper Styles
.site-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  width: 100%;
  height: 100vh;
  .site-content {
	  display: flex;
	  flex-grow: 1;
	  overflow-y: hidden;
	}
	.content {
	  	left: 0px;
	  	display:flex;
	  	flex-direction: column;
	  	transition: all .3s cubic-bezier(.25,.1,.24,1);
		.page {
		  	overflow-y: auto;
		  	height: 100%;
		  	-webkit-overflow-scrolling: touch;
        flex: 1;
		}

	}
}

@media (max-width: 40em) {
	.site-container {
		.site-content {
			.content{
				.page {
					overflow-y: visible;
					height: auto;
					-webkit-overflow-scrolling: auto;
				}
			}
		}
	}
}

// Additional Flex Styles
.flex {
	display: flex;
}

.align-middle {
	align-items: center;
}

.flex-wrap{
	flex-wrap: wrap;
	flex-direction: row;
}

// Fix for Safari Issue
.flex:before, .flex:after{
   display: none;
}
