table {
	background-color: $white;
	color: $dark-gray;
	transition: background-color 250ms ease;
	&.table {
		th {
			font-weight: 400;
			font-size: 12px;
		}
    td {
      &.overflow {
      white-space: nowrap;
      vertical-align: top;
      }
    }
		> thead {
			background-color: transparent;
			> tr > th,
			> tr > td {
				border-color: $outline-gray;
				border-bottom-width: 1px; 
			}
			&:first-child {
				border: 1px solid $outline-gray;
				> tr:first-child > th {
					color: $medium-gray;
					background-color: $background-gray;
				}
			}
		}
		> tbody {
			border: 1px solid $outline-gray;
			> tr > th,
			> tr > td {
				border-top-color: $outline-gray;
			}
			> tr:nth-of-type(even) {
				background-color: $white;
			}
			> tr {
				transition: background-color 250ms ease;
				&.semi-bold {
					font-weight: 500;
				}
				&:hover {
					background-color: darken($white, 2%);
				}

			}

		}
		&.table-small {
			font-size: 12px;
		}
		&.table-large {
			> thead:first-child > tr:first-child > th {
				padding: 15px 10px;
			}
			> tbody > tr > td {
				padding: 15px;
			}
		}
		&.table-striped {
			> thead {
				background-color: transparent;
				> tr > th,
				> tr > td {
					border-color: $outline-gray;
				}
				&:first-child {
					border: 1px solid $outline-gray;
					> tr:first-child > th {
						border: 1px solid $outline-gray;
					}
				}
			}
			> tbody {
				> tr > th,
				> tr > td {
					border: 1px solid $outline-gray;
				}
			}
		}
		&.large-header {
			> thead:first-child > tr:first-child > th {
				font-size: 14px;
			}
			> tbody > tr > td {
				font-size: 12px;
			}
		}
	}
}

