.dropdown-menu {
	&.notification-dropdown {
		max-height: 90vh;
		width: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
		padding: 10px 0px;
		h4 {
			display: inline-block;
			margin-bottom: 0px;
			margin-top: 5px;
		}
		h5 {
			color: $medium-gray;
		}
		.fluid{
        margin-right: auto;
        margin-left: auto;
    }
		p {
			margin-bottom: 5px;
		}
		.dropdown-header {
			margin-bottom: 8px;
		}

	}

}

.notification {
	background-color: $white;
	padding: 8px;
	margin-bottom: 8px;
	h4.uppercase {
		margin-bottom: 0px;
		margin-top: 2px;
	}
	p {
		line-height: normal;
		font-size: 12px;
  }
  .mark-read svg {
    top: 5px;
  }
  .chat {
    display: block;
  }
  word-break:break-all;
}
.notification-settings {
  padding-top: 2rem;
  
	h3 {
		margin-bottom: 10px;
		font-weight: 500;
	}
	.card {
		h3 {
			margin-bottom: 10px;
		}
	}
	.notification-preference-header {
		margin-bottom: 10px;
		border-bottom: 1px solid $outline-gray;
	}
	.notification-preference {
		text-align: center;
	}
	.notification-group {
		margin-top: 16px;
		.notification-group-footer {
			padding-bottom: 8px;
		}
	}
	.notification-type {
		margin-top: 8px;
		margin-bottom: 8px;
		border-bottom: 1px solid $outline-gray;
		padding: 8px;

		p {
			line-height: normal;
			font-size: 12px;
		}
		.toggle {
      .switch-label{
        margin-left: 15px;
      }
			margin-top: 0px;
			margin-bottom: 0px;
			.toggle-label {
				margin-left: 10px;
				font-size: 12px;
			}
		}
	}
	.save-actions {
		margin-top: 40px;
		margin-bottom: 16px;
	}
	.filter-container {
		border-right: none;
		width: initial;
		height: initial;
		.filter-item-container {
			background-color: $light-content-gray;
			padding:8px;
			width: 200px;
			height: 150px;
			overflow-y: scroll;
			display: inline-block;
			margin-right: 10px;
		}
	}
}

.new-alert:before {
	content: '';
    height: 12px;
    width: 12px;
    display: block;
    position: absolute;
    background-color: $error;
    right: 2px;
    border: 2px solid #fff;
    border-radius: 10px;
    z-index: 10;
}

.text-setup {
	.modal {
		label {
			display: inline;
		}
	}
	.custom-select {
		min-width: 100px;
		display: inline-block;
		margin-right: 10px;
		label {
			margin-bottom: 5px;
		}
	}
	.add-phone-number {
		label {
			display: block;
		}
		.form-group {
			display: inline-block;
		}
	}
}

#notificationsDropdown .nav-tabs li a {
  line-height: .03em;
}

.notifications-label {
  font-weight: 400;
  font-size: 17px;
}

.alertsMessage {
	word-break: keep-all;
}

@media (min-width: $break-sm) {
	.toggle-container {
		display: flex;
		justify-content: center;
	}
	.special-margin {
		margin: 0 10px 0 10px;
	}
}

@media (max-width: $break-sm) {
	.toggle {
		width: 100%;
		.toggle-label {
			font-size: 14px;
		}
	}
	.special-margin {
		margin-bottom: 15px
	}
}
