/* Patterned colors */

$white: #ffffff;
$black: #000000;
$background-gray: #f5f5f5;
$font-gray: #363636;
$nav-black: #1a1a1a;
$dark-gray: #333333;
$medium-gray: #666666;
$light-gray: #999999;
$nav-light-gray: #262626;
$navisphere-orange: #f47b20;
$faded-gray: #cccccc;
$outline-gray: #dddddd;
$light-content-gray: #f9f9f9;
$placeholder-gray: #adadad;
$input-border-gray: #a9a9a9;
$disabled-gray: #ebebe4;
$status-early: #2E99E0;
$status-ontime: #7EA122;
$status-atrisk: #D5B100;
$status-late: #BA4B4D;
$active-blue: #337ab7;

$box-shadow: 0 2px 8px 0 rgba(0,0,0,0.10);
$box-shadow-overlay: 0 5px 15px 0 rgba(0,0,0,0.20);

$focus-white: 0 0 6px 3px rgba($white,0.3);
$focus-black: 0 0 6px 3px rgba($black,0.8);
$focus-default: 0 0 6px 0 rgba(74,144,226,0.50);

$break-xs: 415px;
$break-sm: 768px;
$break-md: 992px;
$break-lg: 1441px;
$break-xl: 2539px;