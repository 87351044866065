#sort-options-panel {
	margin-top:40px;
    width: 100%;
    border-radius: 2px;
	font-size: 12px;
	border-width: 1px;
	padding: 2px 4px;
    transition: color 250ms ease, background-color 250ms ease, border-color 250ms ease;
    background-color: white;
	text-decoration: none;
	letter-spacing: .025em;
	word-wrap:none;	
}

.sort-order-option {
	width: 100%;
	max-width: inherit;
}

.sort-order-option-radio-select {
	width: 2.5%;
}

.sort-order-option-radio-label {
	width: 97.5%;	
}

input[type=radio]::after, input[type=radio]:not(:disabled)::after {
	width: 8px;
    height: 8px;
    left: 4px;
    top: 3px;
}