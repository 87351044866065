.individual-shipment {
	&:before {
        height: 100%;
        width: 5px;
        display: block;
        background-color: $outline-gray;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
    }
    &.early:before {
        background-color: $status-early;
    }
    &.ontime:before {
        background-color: $status-ontime;
    }
    &.atrisk:before {
        background-color: $status-atrisk;
    }
    &.late:before {
        background-color: $status-late;
    }
	h4 {
		margin-top: 0px;
		&.uppercase {
			margin-bottom: 0px;
			margin-top: 3px;
			color: $light-gray;
		}
	}
}
.status-text {
		float: right;
	}