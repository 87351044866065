.card {
  &.shipment-card {
      margin-bottom: 10px;
      display: flex;
      padding: 10px 10px 10px 15px;
      &:before {
          height: 100%;
          width: 5px;
          display: block;
          background-color: $outline-gray;
          content: '';
          position: absolute;
          left: 0;
          top: 0;
      }
      &.early:before {
          background-color: $status-early;
      }
      &.ontime:before {
          background-color: $status-ontime;
      }
      &.atrisk:before {
          background-color: $status-atrisk;
      }
      &.late:before {
          background-color: $status-late;
      }
      &.noappointment:before {
        background-color: $status-noappointment;
      }

      .left-container {
          position: relative;
          height: 100%;
          background-color: $light-content-gray;
          padding: 15px;
      }
      .shipment-card-content  {
          padding: 15px 0px;
      }

      .tracker-link {
          position: absolute;
          right: 0;
          bottom: 0;
      }

      .reference-number {
          margin-bottom: 10px;
          width: 100%;
          &.read-only {
              display: inline-block;
          }
      }

      .origin-content,
      .destination-content {
          background-color: $light-content-gray;
          border-radius: 2px;
          padding: 8px;
          height: 100%;
      }
      .arrow-container {
          top: 43%;
          padding: 5px;
          position: absolute;
          > svg {
              opacity: .5;
          }
      }
      .group-section {
          height: 100%;   
      }
      h4 {
          margin-bottom: 8px;
      }
      .reference-number-container {
          // width: 180px;
          border-right: 1px solid $background-gray;
      }
      .shipment-status-information {
          margin-bottom: 5px;
      }
      &.shipment-card-list {
          padding: 8px;
          font-size: 12px;
          height: auto;
          margin-bottom: 5px;

      }
      .read-only {
          min-width: 80px;
          margin-bottom: 8px;
          margin-right: 0px;
      }
      .invisible-break {
        flex-basis: 100%;
        height: 0px;
        margin: 0px;
        border: 0px;
        visibility: hidden;
      }
  }
  &.group-shipment-card {
      height: 100%;
  }
  .flex-center {
      display: flex;
  }
  .read-only-value {
      font-size: 12px;
      margin-bottom: -2px;    
      text-overflow: ellipsis;
      overflow: hidden;
  }
}

@media (max-width: $break-md) {
  .card.shipment-card {
      .origin-content,
      .destination-content {
          margin-bottom: 15px;
      }
      .group-section {
          margin-bottom: 15px;
      }
      .reference-number{
          &.read-only {
              display: inline-block;
              margin-right: 15px;
              &:last-child {
                  margin-right: 0px;
              }
          }
      }
      .tracker-link {
        position: absolute;
        right: 0;
        bottom: 0;
      }
  }
}

@media (max-width: $break-sm) {
  .card.shipment-card {
      .tracker-link {
          width: 100%;
      }
  }

  .card-container {
      flex-direction: column;
  }

  .identifiers-container {
    border-bottom: 1px solid #f5f5f5;
  }
}

@media (min-width: $break-md) {
  .identifiers-container {
    border-right: 1px solid #f5f5f5;
  }
}

@media (max-width: $break-xs) {
  .card.shipment-card {
      .flex {
          display: block;
      }
      .reference-number{ 
          &.read-only {
              display: block;
              margin-bottom: 15px;
          }
      }
  }
  .card.shipment-card {
      &.shipment-card-list {
          .reference-number{ 
              &.read-only {
                  margin-bottom: 5px;
              }
          }
      }
  }
}

