.filter-container {
	min-width: 280px;
  max-width: 280px;
	background-color: $white;
	border-right: 1px solid $outline-gray;
	height: 100%;
	margin-top: -1px;
  overflow-y: scroll;
  .dateFilterAccordion {
    clear:both;
  }
  .dateFilter {
    min-height: 280px;
    .dateType {
      height: 37px; 
    }
  }
  .searchByDate {
    margin-top: 8px; 
  }
  .react-datepicker-wrapper .input-group-btn .btn {
    z-index: 1;
  }
  .dateRange {
    margin-top: 15px; 
    label {
      margin-top: 8px;
      margin-bottom: 8px; 
    }
  }
	button {
		background-color: inherit;
	}
	&.mobile {
		height: auto;
	}
	.filter-container-header {
		border-bottom: 1px solid $outline-gray;
	}
	.filter-container-body {
    padding: 8px;
 
    .btn-group {
			display: flex;
			justify-content: space-between;
      padding: 1px 5px 10px 5px;

      .btn {
        width: 115px;
      }
	  }
	}
	.filter-item-container {
    margin-bottom: 10px;
    padding: 0px;
	}
	.dropdown-toggle {
		border-radius: 0px;
		font-size: 12px;
		border: 1px solid $outline-gray;
		padding: 8px;
		font-weight: 500;
		width: 100%;
		text-align: left;
		margin-bottom: 0px;
		display: flex;
		align-items: center;
		svg {
			top: 0px;
		}
		&:focus,
		&:active {
			box-shadow: none !important;
			outline: none;
			border-color: $dark-gray;
		}
  }

  .panel-group {
    margin-top: 0px;
    margin-bottom: 0px;
  }
	.panel-title {
    background-color: #F5F5F5;
    border: solid .5px lightgrey;
    height: auto;    
  }
  .panel-title a {
    font-size: 14px;
    text-decoration:none;
    display:block;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    width: 100%;
    height: 100%;
  }
  .panel-body{
    padding: 10px 5px 5px 5px;
    max-height: 305px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .panel-body p {
    color: grey;
  }
  .overflow {
    max-width: 11em;
    text-overflow: ellipsis;
		overflow: hidden;
    white-space: nowrap;
    margin-right: 3px;
  }
  .center {
    text-align: center;
  }

  .list-group {
    margin-bottom: 3px;
  }
  ul {
    list-style-type: none;
  }

}
.page.shipment-list.flex.mobile {
	#filtercontainerCollapse {
		position: absolute;
	    width: 100%;
	    z-index: 6;
	    top: 80px;
	    overflow-y: scroll;
	    height: 100%;
	    
	}
}

.applied-filter-container {
	padding: 8px 0px;
	a.clear-filters {
		font-size: 12px;
	}
	.filter-tag {
		background-color: $white;
		border: 1px solid $outline-gray;
		font-size: 12px;
		border-radius: 2px;
		padding: .25rem .5rem;
		display: inline-flex;
		margin-right: 5px;
		cursor: pointer;
		transition: background-color $transition-default;
		&:hover {
			background-color: $background-gray;
		}
		.filter-tag-text {
			max-width: 12em;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-size: .925em;
		}
		.filter-tag-remove {
			margin-left: 5px;
		}
	}
}

.filter-toggle {
	flex-grow: 1;
}

@media (max-width: $break-md) {
	.filter-container {
		max-height: 80%;
		box-shadow: $box-shadow-overlay;
		overflow-y: scroll;
		.filter-item {
			background-color: $background-gray;
			box-shadow: inset 0px -15px 30px -15px $outline-gray;
			margin-bottom: 10px;
			padding: 10px;
		}
	}
	.filter-toggle {
		flex-grow: 0;
		display: none
	}
}

@media (max-width: $break-sm) {
	.applied-filter-container {
		padding-bottom: 0px;
		.filter-tag {
			.filter-tag-text {
				font-size: 11px;
			}
		}
	}
	.filter-container {
		.filter-container-body {
			padding: 15px;
		}
	}
}