.manage-users-page {
  margin-top: 10px;

  .search-container {
    display: block;
    position: relative;

    .search-input-field {
      // prevent search text from sliding under search button
      padding-right: 37px;
    }

    .search-submit-btn {
      position: absolute;
      top: 0px;
      right: 0px;
      border: 1px solid #999;
      padding: 5px 10px;
    }
  }

  .btn-add-user {
    position: relative;
    margin-top: 10px;
    font-weight: 500;

    .btn-icon {
      width: 24px;
      height: 24px;
      margin-bottom: -5px;
      margin-right: 9px;
    }
  }

  .user-list-container {
    margin-top: 20px;

    .centered {
      position: fixed;
    }
    .user-list-table {
      th {
        font-size: 14px;
        font-weight: 500;
        color: #000;
      }
    }
  }
}

.header-buffer {
  padding-top: 70px;
  background-color: #FFFFFF; 
}
.top-buffer {
  margin-top: 20px;
}


.min-height-setting{
  min-height: calc(100vh - 160px);
  padding-bottom: 50px;

  .panel-title{ 
    background-color: #F5F5F5;
    border:solid .5px lightgrey;
    height: 70px;
    padding-left: 20px;
    margin-top:10px;
    padding-right:20px;
  }
  .panel-title a{
    text-decoration:none;
  }
  .help-block {
    color: lightcoral; 
  }
  .panel-title svg{
    float: right; 
  }
  .form-group input{
    border:solid .5px lightgrey;
  }

  .cancel {
    margin-left: 10px;
    width: 92px;
  }

  .delete {
    margin-left: 10px;
    width: 92px;
  }

  .save:disabled {
    color: grey !important; 
    background: lightgrey; 
    border-color: lightgrey; 
    opacity: 1;
  } 
  .invalid {
    border:.5px solid lightcoral !important; 
  } 
  .read-only-value{
    font-size: 16px;
  }
  .tenantName{
    font-size: 15px; 
  }
}