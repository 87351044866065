.tabs {
  .nav-tabs {
    border-bottom: none;
    border-left-width: 1px;
    border-right-width: 1px;

    >li {}

    >li>a {
      color: $dark-gray;
      text-decoration: none;
      border-radius: 0px;
      margin: 0px;
      border: none;
      border-top: 4px solid $outline-gray;
      border-left: 1px solid $outline-gray;
      padding: 15px 30px;
      transition: border-top 250ms ease, background-color 250ms ease;
      height: 100%;

      &:hover {
        border-top: 4px solid $outline-gray;
        border-color: $outline-gray;
      }
    }

    >li:last-child>a {
      border-right: 1px solid $outline-gray;
    }

    >li.active>a,
    >li.active>a:focus,
    >li.active>a:hover {
      border-radius: 0px;
      border-top: 4px solid $navisphere-orange;
      background-color: $tab-content-gray;
      border-left-width: 1px;
      border-right-width: 0px;
      border-bottom: none;

      &:hover {
        border-top: 4px solid darken(rgba($navisphere-orange, 1), 20%);

      }
    }

    >li.active:last-child>a {
      border-right: 1px solid $outline-gray;
    }
  }

  &.tabs-full-width {
    .nav-tabs {
      display: flex;

      >li,
      >li>a {
        width: 100%;
        text-align: center;
      }
    }
  }

  &.tabs-uppercase {
    .nav-tabs {
      >li>a {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: .15em;
        color: $medium-gray;
        line-height: 1em;
        padding: 20px 15px;
      }
    }
  }

  &.tabs-left {
    display: flex;
    flex-direction: row;

    .nav-tabs {
      min-width: 200px;

      >li {
        float: initial;

        >a {
          border-left: 4px solid $outline-gray;
          border-top: 1px solid $outline-gray;
        }

        &.active {
          margin-right: -1px;
          margin-bottom: 0px;
        }
      }

      >li.active>a,
      >li.active>a:hover {
        border-left: 4px solid $navisphere-orange;
        border-top: 1px solid $outline-gray;
      }

      >li:last-child>a {
        border-bottom: 1px solid $outline-gray;
        border-right: none;
      }
    }
  }
}

.tab-content {
  border: 1px solid $outline-gray;
  padding: 15px;
  background-color: $tab-content-gray;
}

@media (max-width: $break-sm) {
  .tabs {
    &.tabs-left {
      display: initial;

      .nav-tabs {
        width: auto;
        margin-bottom: -1px;

        >li {
          float: left;

          >a {
            border-top: 4px solid $outline-gray;
            border-left: 1px solid $outline-gray;
          }

          &.active {
            margin-right: 0px;
            margin-bottom: -1px;
          }
        }

        >li.active>a,
        >li.active>a:hover {
          border-top: 4px solid $navisphere-orange;
          background-color: $background-gray;
          border-left-width: 1px;
          border-left-color: $outline-gray;
          border-bottom: none;
        }

        >li:last-child>a {
          border-right: 1px solid $outline-gray;
          border-bottom: none;
        }
      }
    }

    .nav-tabs>li>a,
    &.tabs-uppercase .nav-tabs>li>a {
      padding: 15px;
    }
  }
}

@media (max-width: 489px) {
  .tabs {
    .nav-tabs {
      display: block;

      >li {
        width: 100%;
      }

    }

    &.tabs-full-width .nav-tabs {
      display: block;
    }
  }
}
