.iot-tracking-header {
  padding: 0;
}

.iot-line-graph {
  padding: 0px 0px 10px 10px;
}

.iot-scatter-graph {
  padding: 0px 0px 10px 10px;
}

.iot-graph-tabs {
  padding-left: 10px;
}

.iot-shock-color {
  height: 8px;
  width: 8px;
  background-color: $status-early;
  border-radius: 50%;

}

.iot-pressure-color {
  height: 8px;
  width: 8px;
  background-color: $active-blue;
  border-radius: 50%;
}

.iot-threshold-legend {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  list-style-type: none;

  > li {
    display: inherit;
    padding: 6px 0px 6px 0px;
    align-items: center; 
  }
  > li > span {
    align-self: center;
  }
  > li > p {
    margin: 0px 6px 0px 6px;
    font-size: 11px;
  }
}

.scatter-graph-tool-tip {
  background-color: white;
  padding: 8px;
  border: 1px solid $active-blue;
  >:first-child {
    margin-bottom: 6px;
  }
}

.graph-tabs {
  display: flex;
  border-bottom: none;
	> li {
    border: 1px solid $outline-gray;
	}
	> li.active {
    border: 2px solid $active-blue;
	}
	> li > a {
    text-decoration: none;
		color: $dark-gray;
		border-radius: 0px;
		margin: 0px;
		border: none;
		background-color: $white;
		border-top: 3px solid transparent;
		padding: 8px 18px;
		transition: border-top 250ms ease, background-color 250ms ease;
  }
}

.iot-shock-pressure-tooltip {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid $active-blue;
  padding: 8px;
  > p {
    margin-bottom: 40px;
  }
}

.iot-shock-pressure-tooltip-label {
  display: flex;
  padding-bottom: 8px;
}

.iot-shock-pressure-tooltip-values {
  display: flex;
  text-transform: capitalize;
}

.iot-details-table {
  padding: 0px;
}

.iot-event-icon {
  margin-right: 6px;
}

.iot-hr {
  margin-top: 4px;
  margin-bottom: 4px;
}

.iot-row-header {
  margin-top: 4px;
}

.iot-column-header {
  font-weight: bold;
}

.iot-anomaly-list-item {
  color: $status-late;
  font-weight: bolder;
  font-style: italic;
}

// IotDetailsTab
.iot-details-list-item-map-container {
  display: flex;
  justify-content: space-between;
}

.iot-details-list-item-map-info {
  display: flex;
  flex-direction: column;
}

.iot-details-list-item-map-info-location {
  font-weight: 700;
}

.iot-details-list-item-map-info-geolocation {
  font-weight: 100;
  font-style: italic;
}

.iot-details-list-item-map {
  display: flex;
  height: 175px;
  width: 200px;
}

.iot-dashboard-anomalies-red-text {
  text-align: center;
  color: $status-late
}

.iot-dashboard-anomalies-green-text {
  text-align: center;
  color: $status-ontime
}
