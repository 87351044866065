.conversation {
  &.error-message {
    padding: 3px;
    background-color: $navisphere-orange;
    text-align: center;
    vertical-align: middle;
  }
  &.group{
    &.circle{
      width: 40px;
      height: 40px;
      padding: 8px;
      background: $medium-gray;
      border-radius: 50%;
      color: white;
      text-align: center;
      display:inline-block;
    }
    &.image{
      margin:-10px;
      border-color: white;
      border-width: 2px;
      border-style: solid;
    }
    &.list-item{
      padding:5px;
      background-color:white;
      &.header{
        padding-top:5px;
      }
    }
  }
  &.hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: .5;
    &:before {
      content: '';
      background: linear-gradient(to right, transparent, #818078, transparent);
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
    height: 1px;
    }
    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      color: black;
      line-height: 1.5em;
      color: black;
      background-color: $background-gray;
    }
  }
  &.auto-suggest{
    &.type_msg{
      border-top: 1px solid #c4c4c4;
      position: relative;
      padding-bottom: 2%;
      margin-top: 2rem;
    }
    &.msg_send_btn {
      margin-right: 0.5rem;
      color: #ffffff;
      cursor: pointer;
      font-size: 17px;
      position: absolute;
      right: 0;
      top: 5px;
      font-size: 23px;
      outline: none;
    }
  }
}