h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    color: $dark-gray;
}

h1 {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 15px;
}
h2 {
    font-size: 20px;
}
h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 14px;
    font-weight: 600;
}
h6 {
    font-size: 14px;
    
}

.uppercase {
    text-transform: uppercase;
    letter-spacing: .15em;
    font-weight: 500;
    color: $medium-gray;
    font-size: 12px;
    margin-top: 0px;
}

a {
    text-decoration: underline;
}
a:hover,
a:focus {
    transition: color 250ms ease;
    text-decoration: none;

}

h1.style-guide-header {
    font-size: 36px;
    border-bottom: 1px solid $outline-gray;
    width: 100%;
    padding-bottom: 10px;
    margin-top: 30px;
}

.text-info {
    color: $info;
}
