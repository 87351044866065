
.card-group {
  display: flex;
  flex-wrap: wrap;

  > [class*="col-"] {
      margin-bottom: 15px;
  }
  .card {
      height: 100%;
  }
}
.card-height {
  margin-bottom: 15px;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: $white;
  box-shadow: $box-shadow;
  padding: 16px;
  // height: 100%;
  h2,
  h3,
  h4,
  h5,
  h6 {
      margin-top: 0px;
      margin-bottom: 0px;
  }
  p {
      margin-bottom: 0px;
      line-height: normal;
  }

  .card-header {
      margin-bottom: 10px;
  }
  .card-content {
      margin-bottom: 8px;
  }
  .card-footer {
      margin-top: auto;
      > svg {
          margin-right: 15px;
      }
      .actions-right {
          float: right;
      }
  }
}

.card-md {
  height: 250px;
}

.card-scroll {
  overflow: auto;
}

.card-centered {
  margin: auto;
}

.card-outline {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 15px;
  background-color: $white;
  border: 1px solid $outline-gray;
  padding: 16px;
  height: 100%;
  box-shadow: none;
}