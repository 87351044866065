.tooltip {
	.tooltip-inner {
		background-color: $dark-gray;
		font-family: 'Open Sans';
		line-height: normal;
		border-radius: 0px;
		text-align: left;
		padding: 5px 8px;
		font-weight: 300;
		letter-spacing: .025em;
	}
}