
.btn {
	border-radius: 2px;
	font-size: 12px;
	border-width: 1px;
	padding: 8px 16px;
	transition: color 250ms ease, background-color 250ms ease, border-color 250ms ease;
	text-decoration: none;
	letter-spacing: .025em;
	&:hover, &:focus {
		text-shadow: none;
		border-bottom-width: 1px;
	}
	&:not(:disabled):not(.disabled):not([disabled]):focus {
		-webkit-box-shadow: 0 0 4px 1px rgba($active-blue, 0.6);
    	box-shadow: 0 0 4px 1px rgba($active-blue, 0.6);
	}
	&:active {
		box-shadow: none;
	}
	&[disabled] {
		opacity: .5;
	}
	&:not(:disabled):not(.disabled) {
		&:hover, &:focus {
			border-width: 1px;
		}
		&:active {
			border-width: 1px;
		}
		&.btn-default {
			background-color: $active-blue;
			color: $white;
			border-color: $active-blue;
			&:hover,
			&:focus,
			&:active,
			&:active:hover {
				background-color: darken($active-blue,10%);
				border-color: darken($active-blue,10%);
				color: $white;
			}
		}
	}

	// PRIMARY BUTTONS
	&.btn-primary {
		background-color: $active-blue;
		border-color: $active-blue;
		font-weight: 400;
		&:not(:disabled):not(.disabled):not([disabled]):hover {
			text-decoration: none;
			color: $white;
			background-color: darken($active-blue,10%);
			border-color: darken($active-blue,10%);
		}
		&:not(:disabled):not(.disabled):not([disabled]):active,
		&:not(:disabled):not(.disabled):not([disabled]):focus {
			text-decoration: none;
		}
		&.btn-default {
			color: $white;
			background-color: $active-blue;
			&:hover {
				background-color: darken($active-blue,10%);
				border-color: darken($active-blue,10%);
				color: $white;
			}
		}
		&.btn-success {
			background-color: $success;
			border-color: $success;
			color: $white;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: $white;
				background-color: darken($success,10%);
				border-color: darken($success,10%);
			}
		}
		&.btn-danger {
			background-color: $error;
			border-color: $error;
			color: $white;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: $white;
				background-color: darken($error, 10%);
				border-color: darken($error, 10%);
			}
		}
		&.btn-warning {
			background-color: $warning;
			border-color: $warning;
			&:not(:disabled):not(.disabled):not([disabled]):hover {

				background-color: darken($warning,10%);
				border-color: darken($warning,10%);
			}
		}
		&.btn-info {
			background-color: $info;
			border-color: $info;
			color: $white;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: $white;
				background-color: darken($info, 10%);
				border-color: darken($info, 10%);
			}
		}
		&.btn-light {
			background-color: lighten($outline-gray,10%);
			border-color: lighten($outline-gray,10%);
			color: $dark-gray;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: $dark-gray;
				background-color: lighten($outline-gray,5%);
				border-color: lighten($outline-gray,5%);
			}
		}
		&.btn-dark {
			background-color: $dark-gray;
			border-color: $dark-gray;
			color: $white;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: $white;
				background-color: lighten($dark-gray,10%);
				border-color: lighten($dark-gray,10%);
			}
		}
    &.btn-semi-bold {
      font-weight: 500;
    }
	}

	// OUTLINE BUTTONS

	&.btn-outline {
		border-color: $active-blue;
		background-color: $white;
		color: $active-blue;
		font-weight: 500;
		transition: all 250ms ease;
		&:not(:disabled):not(.disabled):not([disabled]):hover {
			color: darken($active-blue,10%);
			border-color: darken($active-blue,10%);
			background-color: transparent;
			&.btn-default {
				color: $white;
				background-color: darken($active-blue,10%);
			}
		}

		&.btn-success {
			color: $success;
			background-color: $white;
			border-color: $success;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: darken($success,10%);
				border-color: darken($success,10%);

			}
		}
		&.btn-danger {
			color: $error;
			background-color: $white;
			border-color: $error;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: darken($error, 10%);
				border-color: darken($error, 10%);

			}
		}
		&.btn-warning {
			color: $warning;
			background-color: $white;
			border-color: $warning;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: darken($warning,10%);
				border-color: darken($warning,10%);

			}
		}
		&.btn-info {
			color: $info;
			background-color: $white;
			border-color: $info;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: darken($info, 10%);
				border-color: darken($info, 10%);
			}
		}
		&.btn-light {
			color: $medium-gray;
			background-color: $white;
			border-color: $outline-gray;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: darken($medium-gray, 20%);
				border-color: darken($outline-gray, 10%);
			}
		}
		&.btn-dark {
			color: $dark-gray;
			background-color: $white;
			border-color: $dark-gray;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: darken($dark-gray, 10%);
				border-color: darken($dark-gray, 10%);
			}
		}
	}

	// CLEAR BUTTONS
	&.btn-link {
		color: $active-blue;
		font-weight: 500;
		&:not(:disabled):not(.disabled):not([disabled]):hover {
			color: darken($active-blue,10%);
			text-decoration: underline;
			&.btn-default {
				color: $white;
				text-decoration: none;
				background-color: darken($active-blue,10%);
				&:not(:disabled):not(.disabled):not([disabled]):active {
					text-decoration: none;
				}
			}
		}
		&:not(:disabled):not(.disabled):not([disabled]):active {
			text-decoration: underline;
		}
		&.btn-success {
			color: $success;
			background-color: transparent;
			border-color: transparent;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: darken($success,10%);
				border-color: transparent;
			}
		}
		&.btn-danger {
			color: $error;
			background-color: transparent;
			border-color: transparent;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: darken($error, 10%);
				border-color: transparent;
			}
		}
		&.btn-warning {
			color: $warning;
			background-color: transparent;
			border-color: transparent;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: darken($warning,10%);
				border-color: transparent;
			}
		}
		&.btn-info {
			color: $info;
			background-color: transparent;
			border-color: transparent;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: darken($info, 10%);
				border-color: transparent;
			}
		}
		&.btn-light {
			color: $medium-gray;
			background-color: transparent;
			border-color: transparent;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: darken($medium-gray,10%);
				border-color: transparent;
			}
		}
		&.btn-dark {
			color: $dark-gray;
			background-color: transparent;
			border-color: transparent;
			&:not(:disabled):not(.disabled):not([disabled]):hover {
				color: darken($dark-gray,10%);
				border-color: transparent;
			}
		}
	}

	&.btn-lg {
		padding: 12px 16px;
		font-size: 14px;
	}
	&.btn-sm {
		padding: 4px 8px;
	}
  &.btn-sm-icon-height {
		padding: 5.25px 8px;
	}
	&.space-right {
		margin-right: 15px;
	}
	&.btn-sort-options-select {
		padding-right: 3px;
		margin-right: 3px;
		width: 200px;
		max-width: 200px;
		overflow: hidden; 
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
		text-align: left;
	}
}

button {
	&.clear {
		background-color: transparent;
		border: none;
	}
}

.btn-success {
	border-color: $success;
	background-color: $success;
	&:not(:disabled):not(.disabled):not([disabled]):focus {
		-webkit-box-shadow: 0 0 4px 1px rgba($success, 0.6);
    	box-shadow: 0 0 4px 1px rgba($success, 0.6);
	}
}
.btn-danger {
	border-color: $error;
	background-color: $error;
	&:not(:disabled):not(.disabled):not([disabled]):focus {
		-webkit-box-shadow: 0 0 4px 1px rgba($error, 0.6);
    	box-shadow: 0 0 4px 1px rgba($error, 0.6);
	}
}
.btn-warning {
	border-color: $warning;
	background-color: $warning;
	&:not(:disabled):not(.disabled):not([disabled]):focus {
		-webkit-box-shadow: 0 0 4px 1px rgba($warning, 0.6);
    	box-shadow: 0 0 4px 1px rgba($warning, 0.6);
	}
}

.btn-info {
	background-color: $info;
}

.btn:not(:disabled):not(.disabled):hover,
.btn:not(:disabled):not(.disabled):focus,
.btn:not(:disabled):not(.disabled).focus,
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled).shown {
	text-shadow: none;
	outline: initial;
}

.btn-icon {
	padding: 5px;
	> svg {
		transition: opacity $transition-default;
	}
	&:hover > svg {
		opacity: .7;
	}
}

.btn-group {
	font-weight: 400;
	> button.active {
		background-color: $active-blue;
		color: $white;
	}
	> .btn.active {
		z-index: 0;
	}
}