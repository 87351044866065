$defaultRadioWidth: 16px;
$defaultRadioHeight: 16px;

label {
    font-size: 12px;
    font-weight: 400;
    color: $medium-gray;
    display: flex;
}

::-webkit-input-placeholder,/* Chrome/Opera/Safari */
::-moz-placeholder, /* Firefox 19+ */
:-ms-input-placeholder, /* IE 10+ */
:-moz-placeholder {  /* Firefox 18- */
  font-size: 12px;
  line-height: normal;
  font-style: italic;
  color: red;
}

.form-control {
    color: $dark-gray;
    border-color: $light-gray;
    border-radius: 2px;
    padding: 5px;
}
.input-group .form-control {
  z-index: initial;
}

.form-group {
    .help-text {
      display: inline-block;
      font-size: 12px;
      color: $medium-gray;
    }
    svg {
      margin-right: 3px;
    }
}

.form-horizontal {
	.form-group {
		margin-left: auto;
		margin-right: auto;
	}
  .checkbox {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}


.form-inline {
	input {
		margin-right: 10px;
	}
  .checkbox,
  .radio {
    margin-top: 5px;
  }
}

.checkbox,
.radio {
  margin-top: 0px!important;
  label {
    font-size: 14px;
  }
}
.checkbox-inline,
.radio-inline {
  padding-left: 0px;
}



input[type=checkbox],
input[type=radio] {
  margin: 2px 10px 0 0;
  width: $defaultRadioWidth;
}

[type="checkbox"]{
  width: 0;
  height: 0;
}

[type="radio"]{
  width: 0;
  height: 0;
}

input[type=checkbox]:not(:disabled)::before,
input[type=radio]:not(:disabled)::before {
  top: -1px;
}

input[type=checkbox]:not(:disabled):checked::before,
input[type=radio]:not(:disabled):checked::before,
input[type=checkbox]:not(:disabled)::before,
input[type=radio]:not(:disabled)::before {
  border: 1px solid $light-gray;
  width: $defaultRadioWidth;
  height: $defaultRadioHeight;
  left: 0px;
}

// Checked border color
input[type=checkbox]:not(:disabled):checked::before,
input[type=radio]:not(:disabled):checked::before {
  border-color: $medium-gray;
}

input[type=checkbox]:checked::before,
input[type=checkbox]:not(:disabled):checked::before {
    content:'';
    border-color: $medium-gray;
}

input[type=checkbox]:checked::after,
input[type=checkbox]:not(:disabled):checked::after {
    content:'';
    display: block;
    width: 6px;
    height: 10px;
    border: solid $active-blue;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    border-radius: 0px;
    position: relative;
    left: 5px;
    top: 2px;
}

input[type=radio]::after,
input[type=radio]:not(:disabled)::after {
    width: 8px;
    height: 8px;
    left: 4px;
    top: 4px;
}

input[type=radio]:checked::after,
input[type=radio]:not(:disabled):checked::after {
    background: $active-blue;
}

.checkbox input[type=checkbox]:not(:disabled):checked::before,
.radio input[type=radio]:not(:disabled):checked::before,
.checkbox input[type=checkbox]:not(:disabled)::before,
.radio input[type=radio]:not(:disabled)::before {
  left: -1px;
}

.radio label, .checkbox label {
  padding-left: 23px;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  margin-left: -22px;
}

.checkbox input[type="checkbox"]:focus,
.radio input[type="radio"]:focus {
    &:before {
      -webkit-box-shadow: 0 0 4px 1px rgba($active-blue, 0.5);
      box-shadow: 0 0 4px 1px rgba($active-blue, 0.5);
    }

}
// File Input

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  + label {
    font-size: 12px;
    color: $active-blue;
    background-color: $white;
    font-weight: 500;
    padding: 4px 12px;
    border-radius: 2px;
    display: inline-block;
    cursor: pointer;
    transition: background-color 250ms ease;
  }
  &:focus + label,
  + label:hover {
      text-decoration: underline;
  }
  &:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

}

.toggle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .switch-input {
  display: none;
  }
  .switch-label {
      position: relative;
      display: inline-block;
      cursor: pointer;
      height: 20px;
      width: 35px;
      margin-right: 10px;
      margin-bottom: 0;
  }

  .switch-label:before {
      width: 35px;
      height: 15px;
      background-color: $outline-gray;
      border-radius: 20px;
      content: '';
      position: absolute;
      margin: 0;
      outline: 0;
      top: 50%;
      -ms-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
  }
  .switch-label:after {
      left: 0;
      width: 20px;
      height: 20px;
      background-color: $medium-gray;
      border-radius: 50%;
      content: url('assets/close.svg');
      position: absolute;
      margin: 0;
      outline: 0;
      top: 50%;
      -ms-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      text-align: center;
      padding: 2px;
  }

  .switch-input:checked + .switch-label:before {
      background-color: $outline-gray;
  }
  .switch-input:checked + .switch-label:after {
      background-color: $active-blue;
      -ms-transform: translate(15px, -50%);
      -webkit-transform: translate(15px, -50%);
      transform: translate(15px, -50%);
      content: url("assets/check.svg");
  }

  &.large {
    font-size: 16px;
    .switch-label {
      width: 40px;
    }
    .switch-label:before {
      height: 20px;
      width: 38px;
    }
    .switch-label:after {
      width: 25px;
      height: 25px;
      padding: 4px;
    }
    .switch-input:checked + .switch-label:after {
      -ms-transform: translate(15px, -50%);
      -webkit-transform: translate(15px, -50%);
      transform: translate(15px, -50%);
    }
  }
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: $medium-gray;
    text-align: center;
    background-color: $light-content-gray;
    border: 1px solid $medium-gray;
    border-radius: 2px;
}
