.export-selection-modal { 
  padding: 0px 0px 15px 0px;

  span {
    font-style: italic;
  }

}

.export-selection-box {
  overflow: auto;
  max-height: 300px;
  padding: 5px;
}

