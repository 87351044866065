@import '../../../styles/constants.scss';
@import '../../../styles/_variables.scss'; 
@import '../../../styles/_shipment-tracker.scss';
@import '../../../styles/_iot.scss';

$small: 580px;
$medium: 800px;

tr.date-group-label-row {
  td {
    font-weight: 450;
  }
}

.commodities-value {
  font-size: 1.5rem;
  font-weight: 400;
}

.commodities-overview {
  padding-bottom: 15px; 
}

.left {
  float: left;
}

.right {
  float: right;
}

.main_container {
  padding: 50px 75px;
  width: 80%;
  @media screen and (max-width: $medium) {
    padding: 0;
  }
  @media screen and (max-width: $small) {
    font-size: 12px;
    width: 100%;
  }
}

.header {
  padding: 15px;
  border: 1px solid #ccc;
  border-color: #000;

  .split-group-container {
    width: 100%;
    display: inline-block;
  }

  h1 {
    display: inline-block;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    @media screen and (max-width: $medium) {
      font-size: 26px;
    }
    @media screen and (max-width: $small) {
      font-size: 20px;
    }
  }

  .split_group_container{
    display: inline-block;
    width: 100%;
  }
  .split_group.left {
    display: inline-block;
  }

  .warehouse_stop_datetime_label {
    font-weight: 600;
    font-size: 24px;
    @media screen and (max-width: $medium) {
      font-size: 18px;
    }
    @media screen and (max-width: $small) {
      font-size: 14px;
    }
  }

  .entrega_label {
    display: block;
    text-align: right;
    @media screen and (max-width: $medium) {
      font-size: 12px;
    }
  }


  .shipment_status_bubble_container {
    vertical-align: text-bottom;
    display: inline-block;
    border: 1px solid #ccc;
    border-color: #000;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
  }

  .header_bottom_container {
    margin-top: 15px;
    .line {
      border: 1px solid rgba(51, 51, 51, 0.5);

    }
    .header_bottom {
      margin-top: 20px;
      margin-bottom: 10px;
      text-align: right;
      @media screen and (max-width: $small) {
        span {
          display: block;
        }
        text-align: center;
      }
    }
    .pending_deliveries_button {
      margin-left: 20px;
      width: 197px;
      height: 35px;
      background: #FFFFFF;
      border: 2px solid #333333;
      border-radius: 4px;
      @media screen and (max-width: $small) {
        margin-top: 15px;
        margin-left: 0;
      }
    }
  }
}

.list-of-orders-content {
  h2 {
    @media screen and (max-width: $medium) {
      padding: 0;
    }
    @media screen and (max-width: $small) {
      font-size: 14px;
    }
  }
  .header-with-line {
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
    @media screen and (max-width: $small) {
      padding: 0 18px;
    }
  }
  
  .header-with-line > hr {
    border-top: 2px solid #ddd;
    flex-grow: 1;
    @media screen and (max-width: $small) {
      margin-bottom: 17px;
    }
  }

  .order-information-title-container {
    @media screen and (max-width: $small) {
      text-align: center;
    }
  }
}



.order {
  margin-top: 32px;
  padding: 16px;
  background: #FFFFFF;
  @media screen and (max-width: $small) {
    margin-top: 16px;
  }
  .split-group-container {
    width: 100%;
    display: inline-block;
  }

  h1 {
    display: inline-block;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    @media screen and (max-width: $medium) {
      font-size: 26px;
    }
    @media screen and (max-width: $small) {
      font-size: 20px;
    }
  }

  .test {
    display: flex;
    width: 100%;
    margin-bottom: 35px;
    margin-top: 35px;
  }

  .split-group-1 {
    margin-right: auto;
    padding: 10px 24px;
    width: 39%;
    background: #f5f5f5;
    border: 1px solid #333333;
  }

  .arrow {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    //padding: 10px 24px;
  }

  .split-group-2 {
    margin-left: auto;
    padding: 10px 24px;
    width: 39%;
    background: #f5f5f5;
    border: 1px solid #333333;
  }

  .stopLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #666666;
  }

  .stopFacility {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    @media screen and (max-width: $medium) {
      padding: 0;
    }
    @media screen and (max-width: $small) {
      font-size: 12px;
    }
  }

  .stopCarrierInfo {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #333333;
  }
}

// Side MENU & PAGE CONTENTS
.side-menu-container {
  line-height: 16px;
  letter-spacing: 1.5px;
  font-weight: 500;
  text-transform: uppercase;
  color: #000000;

  padding: 50px 20px;
  @media screen and (max-width: $medium) {
    padding: 0;
  }
  @media screen and (max-width: $small) {
    font-size: 12px;
    display: none;
  }
  width: 20%;
  border-left: 1px solid #DADADA;

 .navigation-menu {
    top: 100px;
    bottom:70px;
    position:fixed;
    overflow-y:auto;
    overflow-x:hidden;

    button {
      background: none!important;
      border: none;
      padding: 0!important;
      text-decoration: underline;
      cursor: pointer;
      line-height: 16px;
      letter-spacing: 1.5px;
      font-weight: 500;
      text-transform: uppercase;
    }
 }

 .navigation-top-label {
    padding-bottom: 15px;
  }

  .navigation-label {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 16px;
    margin-left: 14px;
    border-left: 2px solid rgba(0, 0, 0, 0.12);
  }

  .navigation-order {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 32px;
    margin-left: 14px;
    border-left: 2px solid rgba(0, 0, 0, 0.12);
  }
}

.page_container {
  display: flex;
}

.modal-title {
  color: #333333 !important;
  font-weight: 600 !important;
  font-size: 24px !important;
}

.pending_orders_table {
  font-family: 'Open Sans';
  border: 1px solid #000;
  
  th {
    background: #EEEEEE;
    font-weight: 400;
    font-size: 12px;
    width: 198px;
    height: 42px;
    border: 1px solid #000;
    padding: 8px;
  }
  td {
    width: 198px;
    height: 42px;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #000;
    padding: 8px;

    a {
      color: #333333;
    }
  }

  td.large {
    width: 480px;
  }
}

.progress_bar_container {
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;
    margin-top: 20px;
    text-align: center;

  .flex-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
  }
  .progress_bar_label {
    color: #333333;
    flex-basis: 33.3%;
    font-weight: 600;
    font-size: 16px;
    @media screen and (max-width: $medium) {
      font-size: 14px;
    }
    @media screen and (max-width: $small) {
      font-size: 12px;
    }
  }
  .image_container {
    img {
      max-height: 100%;
      max-width: 100%;
      vertical-align: middle;
    }
  }
}

