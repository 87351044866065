.inventory {
  .inventory-search {
    .form-group {
      .input-search {
        float: right;
        border: 2px solid;
        border-color: #d3d3d3;
        margin-top: 2rem;
        width: 250px!important;
        margin-right: 15px;
        background-color: #fff;
      }
    }
  }
  #searchsku:focus {
    outline: none;
  }
  .dropdown {
    display: inline-block;
  }
  .dropdown-toggle {
    border-radius: 0px;
    font-size: 12px;
    border: 1px solid $outline-gray;
    padding: 8px;
    font-weight: 500;
    width: 100%;
    text-align: left;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    min-width: 100px;
    svg {
      top: 0px;
    }
    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none;
      border-color: $dark-gray;
    }
  }
  form {
    >.form-group {
      display: inline-block;
    }
  }
  input[type=search] {
    background-color: $white;
    padding: 5px 5px 5px 5px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    width: 250px !important;
  }
  .inventory-search-remove {
    position: absolute;
    top: 0;
    margin-top: .20rem;
    right: 2rem;
    z-index: 50;
  }
}

.italic {
  p {
    font-size: 12px;
    color: $medium-gray;
    font-style: italic;
  }
}

.form-group {
  &.inventory-search {
    margin-bottom: 0px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  background: $white;
}

.inventory-filter-content {
  display: flex;
  align-items: center;
}

.dropdown-menu {
  &.inventory-filter {
    max-height: 300px;
    overflow-y: scroll;
  }
}
.space-top {
  margin-top: 1rem;
}
.float-right {
  float: right;
}
.inventory-count-label {
  float: right;
  padding-right: 15px;
  .inventory-count {
    float: right;
    font-weight: 600;
    font-size: 20px
  }
}

@media (max-width: $break-sm) {
  .inventory {
    .inventory-search {
      width: 100%;
      .form-group {
        width: 100%;
      }
    }
    .btn-download {
      float: initial;
    }
  }
  .inventory-filter-content {
    display: initial;
  }
  .italic {
    &.float-right {
      float: right;
    }
  }
}
