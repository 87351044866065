.panel-group {
	margin-top: 15px;
}
.panel {
	border-radius: 0px;
	border: none;
	box-shadow: $box-shadow;
	>.panel-heading {
		background-color: $white;
		border-radius: 0px;
		padding: 0px;
	}
	> .panel-body {
		padding: 15px;
		background-color: $white;
	}
	&.active {
		margin-bottom: 5px;
	}
}
a.accordion-link {
	padding: 15px;
	display: block;
	width: 100%;
	text-decoration: none;
	color: $dark-gray;
	transition: all $transition-default;
	position: relative;
	&:after {
		background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20'> <polygon id='path-1' points='1.175 0 5 3.81666667 8.825 0 10 1.175 5 6.175 0 1.175'></polygon></svg>") no-repeat;
		content: '';
		position: absolute;
		height: 20px;
		width: 20px;
		right: 5px;
		top: 46%;
	}
	&:hover {
		box-shadow: $box-shadow-overlay;
	}
	.title {
		margin-top: 0px;
		margin-bottom: 0px;
	}
}