$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$base-font-partial-path: "~@chr/autoStyle/dist/";

@import "../node_modules/@chr/autoStyle/dist/sass/chrBootstrap_chrNavisphere_relative";

@import "app/styles/constants.scss";
@import "app/styles/_variables.scss";

@import "app/styles/_accordions.scss";
// @import 'app/styles/_alerts.scss';
// @import 'app/styles/_app-styles.scss';
// @import 'app/styles/_badge.scss';
@import 'app/styles/_buttons.scss';
@import 'app/styles/_cards.scss';
// @import 'app/styles/_components.scss';
@import 'app/styles/_downloads.scss';
@import "app/styles/_dropdowns.scss";
@import 'app/styles/_footer.scss';
@import 'app/styles/_forms.scss';
@import 'app/styles/_header.scss';
@import 'app/styles/_icon.scss';
@import 'app/styles/_landing.scss'; 
@import 'app/styles/_layout.scss';
@import 'app/styles/_list-view.scss'; 
@import 'app/styles/_map-controls.scss';
// @import "app/styles/_mixins.scss";
// @import 'app/styles/_navigation.scss';
@import 'app/styles/_notifications.scss';
// @import 'app/styles/_pagination.scss';
@import 'app/styles/_popovers.scss';
@import 'app/styles/_read-only-data.scss';
@import "app/styles/_shipment-cards.scss";
@import 'app/styles/_shipment-tracker.scss';
@import 'app/styles/_sortOptionsPanel.scss';
@import 'app/styles/_saved-items.scss';
@import 'app/styles/_tables.scss';
@import 'app/styles/_tabs.scss';
@import 'app/styles/_tooltips.scss';
@import 'app/styles/_typography.scss';
@import 'app/styles/_bing-map-overrides.scss';
@import 'app/styles/_toasts.scss';
@import 'app/styles/_inventory.scss';
@import 'app/styles/_filters.scss';
@import 'app/styles/_facility-filters.scss';
@import 'app/styles/_manage-users.scss';
@import 'app/styles/_conversation.scss';
@import 'app/styles/scorecard.scss';
@import 'app/styles/_createPassword.scss';
@import 'app/styles/export-modal.scss';

a,
a:hover,
a:focus {
  transition: color 250ms ease;
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans",sans-serif;
  background-color: $background-gray;
  color: $font-gray;
}

iframe {
	border: none;
}

.icon {
  position: relative;
  height: 1.25em;
  width: 1.25em;
  fill: $medium-gray;
  transition: fill 250ms ease;
  top: 0.15em;
  margin: 0 .3em 0 0;
}

.icon.large {
  width: 2.5em;
  height: 2.5em;
}

.icon.white {
  fill: $white;
}

.space-right {
  margin-right: 5px;
}

.space-left {
  margin-left: 10px;
}

.main-map {
  position: relative;
}

.error-container .site-content .page {
	margin-left: auto;
	margin-right: auto;
	justify-content: center;
	padding: 20px;
}

// Additional Flex Styles
.flex {
	display: flex;
}
.flex-column {
	display: flex;
	flex-direction: column;
	width: 100%;
	.container-fluid {
		width: 100%;
	} 
}

.flex-row {
	flex-direction: row;
}

.flex-row-center {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.page-container {
	.container-fluid {
		width: 100%;
	}
}
.flex-inline {
	display: inline-flex;
}

.flex-center {
	display: flex;
	justify-content: center;
}

.align-middle {
	align-items: center;
}

.flex-wrap{
	flex-wrap: wrap;
	flex-direction: row;
}
.push-right {
	margin-left: auto;
}
.no-pad {
	padding-left: 0px;
	padding-right: 0px;
}

.no-pad-right {
	padding-right: 0px;
}
.no-pad-left {
	padding-left: 0px;
}
.inline {
	display: inline-block;
}

.flex-one {
	flex: 1;
}

.flex-row {
	display: flex; 
	flex-direction: row;
}

.align-left {
	text-align: left;
}
.align-right {
	text-align: right;
}
.margin-auto {
	margin: auto;
}
.centered {
  top: 50%;
  left: 50%;
	position: fixed;
	transform: translate(-50%, -50%);
}

.mapCentered {
	top: 50%;
  left: 40%;
	position: fixed;
	transform: translate(-50%, -50%);
}