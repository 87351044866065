.shipment-tracker {
	.map-controls {
		button {
			position: absolute;
			z-index: 5;
			bottom: 15px;
			right: 15px;
		}
		.map-control-content {
		    box-shadow: $box-shadow-overlay;
		    background-color: $white;
		    padding: 15px;
		    width: 240px;
		    position: absolute;
		    z-index: 1000;
		    display: block;
		    bottom: 70px;
		    right: 15px;
		    &.closed {
		    	display: none;
		    }
		    h4 {
		    	margin-top: 0px;
		    }
		    .map-type {
		    	.radio {
		    		img {
		    			margin-right: 10px;
		    		}
		    		label {
		    			padding: 10px;
		    			transition: background-color $transition-default;
		    			display: flex;
		    			align-items: center;

		    			&:hover {
		    				background-color: $light-content-gray;
		    			}
		    		}
		    		input[type=radio] {
		    			cursor: pointer;
		    			width: 100%;
		    			height: 100%;
		    			margin-left: initial;
		    			opacity: 0;
		    			margin-right: 0px;
		    			&::before,
		    			&::after {
		    				display: none;
		    			}

		    		}
		    		input[type=radio]:not(:disabled):checked {
		    			+ label {
		    				background-color: $light-content-gray;
		    				&:after {
								    content:'';
								    display: block;
								    width: 7px;
								    height: 14px;
								    border: solid $medium-gray;
								    border-width: 0 3px 3px 0;
								    transform: rotate(45deg);
								    border-radius: 0px;
								    position: absolute;
								    right: 20px;
								    top: 30%;

		    				}
		    			}
		    		}
		    	}
		    }
		    .toggle {
		    	display: flex;
		    	padding: 6px 0px;
		    	.switch-label {
		    		margin-left: auto;
		    	}
		    }
		}
	}
}

.split-view {
	.map-controls {
		button {
			position: absolute;
			z-index: 5;
			bottom: 30px;
			right: 15px;
		}

		.map-control-content {
		    box-shadow: $box-shadow-overlay;
		    background-color: $white;
		    padding: 15px;
		    width: 240px;
		    position: absolute;
		 	  z-index: 1000;
		    display: block;
		    bottom: 75px;
		    right: 15px;
		    &.static {
		    	position: re
		    }
		    &.closed {
		    	display: none;
		    }
		    h4 {
		    	margin-top: 0px;
		    }
		    .map-type {
		    	.radio {
		    		label {
						padding: 6px;
						margin-left: -5px;
		    			transition: background-color $transition-default;
		    			display: flex;
		    			align-items: center;

		    			&:hover {
		    				background-color: $light-content-gray;
		    			}
		    		}
		    		input[type=radio] {
		    			cursor: pointer;
		    			width: 100%;
		    			height: 100%;
		    			margin-left: initial;
		    			opacity: 0;
		    			margin-right: 0px;
		    			&::before,
		    			&::after {
		    				display: none;
		    			}

		    		}
		    		input[type=radio]:not(:disabled):checked {
		    			+ label {
		    				background-color: $light-content-gray;
		    				&:after {
								    content:'';
								    display: block;
								    width: 7px;
								    height: 14px;
								    border: solid $medium-gray;
								    border-width: 0 3px 3px 0;
								    transform: rotate(45deg);
								    border-radius: 0px;
								    position: absolute;
								    right: 20px;
								    top: 30%;

		    				}
		    			}
		    		}
		    	}
		    }
		    .toggle {
		    	display: flex;
		    	padding: 6px 0px;
		    	.switch-label {
		    		margin-left: auto;
		    	}
		    }
		}
	}
}


.map-controls {
	button {
		position: absolute;
		z-index: 5;
		bottom: 30px;
		right: 15px;
	}

	.map-control-content {
	    box-shadow: $box-shadow-overlay;
	    background-color: $white;
	    padding: 15px;
	    width: 240px;
	    position: absolute;
	    z-index: 1000;
	    display: block;
	    bottom: 80px;
	    right: 15px;
	    &.static {
	    	position: re
	    }
	    &.closed {
	    	display: none;
	    }
	    h4 {
	    	margin-top: 0px;
	    }
	    .map-type {
	    	.radio {
	    		label {
					padding: 6px;
					margin-left: -5px;
	    			transition: background-color $transition-default;
	    			display: flex;
	    			align-items: center;

	    			&:hover {
	    				background-color: $light-content-gray;
	    			}
	    		}
	    		input[type=radio] {
	    			cursor: pointer;
	    			width: 100%;
	    			height: 100%;
	    			margin-left: initial;
	    			opacity: 0;
	    			margin-right: 0px;
	    			&::before,
	    			&::after {
	    				display: none;
	    			}

	    		}
	    		input[type=radio]:not(:disabled):checked {
	    			+ label {
	    				background-color: $light-content-gray;
	    				&:after {
							    content:'';
							    display: block;
							    width: 7px;
							    height: 14px;
							    border: solid $medium-gray;
							    border-width: 0 3px 3px 0;
							    transform: rotate(45deg);
							    border-radius: 0px;
							    position: absolute;
							    right: 20px;
							    top: 30%;

	    				}
	    			}
	    		}
	    	}
	    }
	    .toggle {
	    	display: flex;
	    	padding: 6px 0px;
	    	.switch-label {
	    		margin-left: auto;
	    	}
	    }
	}
}
