.external-tracker {
  h4 {
    margin-top: -8px;
    text-align: center;
  }
  h5 {
    margin-top: -8px;
    text-align: center;
  }
  h6 {
    margin-top: -8px;
    text-align: center;
  }
  .external-tracker-group {
    margin-top: 40px;
    margin-bottom: 40px;
    .flex-group {
      h1 {
        text-align: center;
      }
      display: flex;
      flex-direction: row;
      .split-group {
        flex-basis: 30%;
        border:1px solid black;
        margin-right: 20px;
        padding: 20px;
      }
    }
  }
  .order-progress-box-group {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 500px;
    border:1px solid black;
    .flex-group {
      h1 {
        text-align: center;
      }
      h5 {
        color: black;
      }
      h5 {
        color: black;
      }
      display: flex;
      flex-direction: row;
      .split-group {
        flex-basis: 40%;
        margin-right: 20px;
        padding: 20px;
      }
    }
  }
}