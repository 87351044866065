.page-control-header {
	border-bottom: 1px solid $outline-gray;
}

.list-view {
	display: flex;
	width: 100%;
	height: 100%;
	position: relative;
	.map-split {
		order: 2;
	    background: #a9c8f2;
	    width: 70%;
	    display: flex;
	    position: relative;
	}
	.details-container {
		padding-top: 10px;
	    width: 100%;
	    max-height: 100%;
	    display: flex;
	    flex-direction: column;
	    overflow-y: auto;
	}
}

.split-section {
	height: 100%;
	.details-container {
		height: 100%;
	}
	&.map-only {
		position: relative;
		.details-container {
			display: none;
		}
		.map-split {
			position: absolute;
	    	height: 100%;
				width: 100%;
		}
	}
	&.list-only {
		.map-split {
			display: none;
		}
		overflow-y: hidden;
	}
}
.shipment-list-information {
	margin-bottom: 10px;
	// display: flex;
	align-items: center;
}
.list-header {
	padding: 5px 5px 5px 10px;
}

.read-only {
  vertical-align: top;
}

.map-display-control , .shipment-card-controls {
	float: right;
	.radio {
		margin: 0px;
	}
	.radio input[type="radio"] {
		display: none;
	}
	label {
		font-size: 12px;
	}
}

.card-container {
	display: flex;
	flex-direction: row;
}

.identifiers-container {
	display: flex;
	flex-basis: 15%;
	padding-right: 15px;
	flex-direction: column;
	margin-right: 15px;
}

.icon-container {
	margin-top: auto;
}

.card-content-container {
	flex: 1;
	flex-direction: column-reverse;
	width: 100%;
}

.tracker-button-container {
	flex-basis: 100%;
	margin-top: 15px;
	padding: 15px;
	max-height: 10%;
	position: relative;
}

.origin-destination {
	margin-top: 5px;
	flex-basis: 100%;
}

.origin-destination-status {
	display: flex;
	flex: 1;
	flex-direction: column-reverse;
	width: 100%;
}

.status-container {
	flex-basis: 100%;
}

.status-container-fields {
	display: flex;
	flex-direction: row;
	justify-content: end;
	flex-wrap: wrap;
}

.split-origin-destination {
	margin-top: 5px;
	flex-basis: 100%;
}

.split-origin-destination-status {
	display: flex;
	flex: 1;
	flex-direction: column-reverse;
	width: 100%;
}

.split-status-container {
	flex: 1; 
}

.split-status-container-fields {
	display: flex;
	flex-direction: row;
	justify-content: end;
	flex-wrap: wrap;
	margin-bottom: .5em;
}

.status-container-fields > .read-only {
	max-width: 180px;
	padding-right: 15px;
}

.split-status-container-fields > .read-only {
	max-width: 180px;
	padding-right: 15px;
}

.origin-content {
	display: flex;
	flex-direction: column;
	.origin-name {
		flex: 1;
	}
	.origin-times {
		padding-top: 1em;
		flex-wrap: wrap;
	}
	.read-only {
		vertical-align: bottom;
		margin-right: 5px !important;
	}
}

.data-science-icon {
	margin-left: .25em;
	margin-right: .25em;
	color: #337AB7;
}

.destination-content {
	display: flex;
	flex-direction: column;
	.destination-name {
		flex: 1;
	}
	.destination-times {
		padding-top: 1em;
		flex-wrap: wrap;
	}
	.read-only {
		vertical-align: bottom;
		margin-right: 5px !important;
	}
}

.bottom-border {
	padding-bottom: .5em;
	border-bottom: 1px solid #d1d1d1;
}

.pick-drop-name-text {
	text-transform: uppercase;
	letter-spacing: .05em;
	font-weight: 500;
	color: #444444;
	font-size: 16px;
	margin-top: 0px;
}

.pick-drop-loc-text {
	text-transform: uppercase;
	letter-spacing: .05em;
	font-weight: 400;
	color: #666666;
	font-size: 12px;
	margin-top: 0px;
}

@media (max-width: $break-md) {
	.page {
		&.shipment-list {
			flex-direction: column;
		}
	}
	.filters-container {
		width: 100%;
	}
}

@media (min-width: $break-lg) {
	.status-container-fields {
		flex-direction: column;
	}
	.status-container-fields > .read-only {
		max-width: none !important;
	}
	.origin-destination-status {
		flex-direction: row;
	}
	.origin-destination {
		flex-basis: 75%;
	}
	.status-container {
		flex-basis: 25%;
		margin-left: 15px;
		padding-left: 15px;
		padding-top: 10px;
	}
}

//wish we had element queries set up to drive this based off of the card size but i'll do this for now
@media (min-width: $break-xl) {
	.split-status-container-fields {
		flex-direction: column;
	}
	.split-status-container-fields > .read-only {
		max-width: none !important;
	}
	.split-origin-destination-status {
		flex-direction: row;
	}
	.split-origin-destination {
		flex-basis: 75%;
	}
	.split-status-container {
		flex-basis: 25%;
		margin-left: 15px;
		padding-left: 15px;
		padding-top: 10px;
	}
}

a.card-link {
	display: block;
    text-decoration: none;
    transition: box-shadow 250ms ease;
    color: #333333;
    &:hover {
    	box-shadow: $box-shadow-overlay;
    }
}

.list-table-header {
	padding: 8px;
	font-size: 12px;
	color: $medium-gray;
	.date-label {
		width: 70px;
		margin-right: 10px;
		margin-bottom: 0px;
		display: inline-block;
	}
}
.page-header {
	// border-bottom: 1px solid $outline-gray;
	background-color: $white;
	margin: 0px;
	padding: 5px 0px 3px 0px;
	h4 {
		margin-top: 10px;
	}
}
.shipment-card-list {
	.read-only {
		// width: 70px;
		margin-right: 10px;
    margin-bottom: 0px;
    .invisible-break {
      flex-basis: 100%;
      height: 0px;
      margin: 0px;
      border: 0px;
      visibility: hidden;
    }
	}
	.reference-number {
		&.read-only {
			margin-bottom: 0px;
			> .read-only-label,
			> .read-only-value {
				display: inline;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
  }
  .reduce-padding {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.btn-search {
	width: 100%;
	padding: 10px;
	border: none;
}

.list-controls {
	float: right;
	.btn-group > button.active {
		border-color: $active-blue;
		color: $active-blue;
		box-shadow: none;
		> svg {
			fill: $active-blue;
		}
		&:hover {
			>svg {
				fill: $medium-gray;
			}
		}
	}
	.btn-group > .btn {
		margin-left: 3px
	}
}

.list-only {
	.shipment-card-view {
		.shipment-status-information {
			.read-only {
				display: block;
			}
		}
	}
	.shipment-list-view {
		.read-only {
			.read-only-time,
			.read-only-value {
				display: inline;
				overflow-wrap: break-word;
				overflow: hidden;
				max-width: 12em;
			}
		}
	}
}

.outage-alert {
	margin: 0px 15px 15px 15px;
}
.outage {
	background-color: #fcf9e2;
	padding: 16px;
	color: #333;
}

.shipment-card-view {
	.shipment-status-information {
		.read-only {
			margin-right: 10px;
		}
	}
}

.split-section {
    &.split-view {
        .location-information {
            width: 100%;
        }
        .main-shipment-information {
            flex-direction: column-reverse;
        }
        .shipment-status-information {
            width: 100%;
            .read-only {
                min-width: 100px;
                max-width: 180px;
                display: inline-block;
            }
        }
		
    }
    &.list-only {
        .main-shipment-information {
            flex-direction: row;
        }
    }

}

.flex.split-section.split-view {
	.shipment-list-view {
		.number-switch {
			width: 100%;
		}
	}
}



@media (min-width: $break-md) {
	.collapse.dont-collapse-sm {
    	display: block;
    	height: auto !important;
    	visibility: visible;
  	}

  	
}

@media (max-width: $break-sm) {
	.map-display-control , .shipment-card-controls {
		text-align: left;
		float: initial;
		padding: 5px 0px;
	}
	.list-controls {
		float: initial;
		padding: 8px 0px;
		.btn-download {
			float: left;
		}
		.btn-group {
			float: right;
		}
	}
	span.list-only.radio.radio-inline,
	span.map-only.radio.radio-inline {
		width: 49%;
		label {
			width: 100%;
		}
	}
	.map-display-control .split-view , .shipment-card-controls {
		display: none;
	}
	.card.shipment-card {
		.row.flex.flex-row {
			flex-direction: column;
		}
		.reference-number-container {
			border-bottom: 1px solid $outline-gray;
			margin-bottom: 8px;
			.reference-number {
				.read-only {
					margin-bottom: 8px;
				}
			}
		}
	}
}

@media (max-width: $break-xs) {
	span.label {
		margin-top: 10px;
	}
}



@media (max-width: $break-lg) {
	.flex.split-section {
		flex-direction: column;
	}
	.list-view {
		.details-container {
			order: 2;
		}
		.map-split {
			order: 1;
			width: 100%;
			height: 50%;
		}
	}
	.mapCentered {
		top: 50%;
		left: 40%;
		position: fixed;
		transform: translate(-50%, -50%);
	}

	.flex-row.full-width.main-shipment-information {
		flex-direction: column-reverse;
	}
	.split-section {
	    &.list-only {
	        .main-shipment-information {
	            flex-direction: column-reverse;
	        }
	    }
	}
	.list-only {
		.shipment-card-view {
			.shipment-status-information {
				.read-only {
					min-width: 100px;
					
					display: inline-block;
					
				}
				
			}
		}
	}
}