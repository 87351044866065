.utility-nav {
	float: right;
}

//=======================================================================//////
// Header & Utility Navigation
//=======================================================================//////

.navbar-header {
  float: initial;
}

.header-container {
    position: sticky;
		transition: all;
		transition-duration: 250ms;
    top: 0;
    width: 100%;
  	background-color: $dark-gray;
  	border-bottom: 1px solid $black;
  	box-sizing: border-box;
  	z-index: 2;
	h1 {
	  	margin-top: 5px;
	  	margin-bottom: 0px;
	}
	ul {
	  	list-style: none;
	  	margin-bottom: 0px;
	}
	ul.utility-nav{
		height: 64px;
	  	padding-left: 0px;
	}
	li.utility-nav-item {
      display: inline-flex;
      padding: .75em .5em;
	}
	#menu-mobile {
		-webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
		filter: invert(100%);	
	}
	.utility-nav-item {
		svg {
			-webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
			filter: invert(100%);
		}
		top: 15%;
    div{
	  	> a {
			transition: 250ms ease;
			display: block;
			svg {
		  		width: 23px;
		  		height: 23px;
			}
			&:hover,
			&:focus {
		  		opacity: 1;
			}
	  	}
	  	&.new-alert:after {
			content: url("assets/new-alert.svg");
			height: 9px;
			width: 9px;
			display: block;
			position: absolute;
			top: 4px;
			right: 7px;
      }
    }
	}

  	.mobile-logo {
		text-align: center;
		.logo {
		  	width: 25px;
		  	padding: 10px 0px;
		}
	}
	.utility-toggle {
		background: none;
		border: none;
		opacity: .5;
		transition: opacity 250ms ease;
		&:hover,
		&:focus {
			opacity: 1;
		}
	}
	svg {
		width: 20px;
	  	height: 20px;
	  	fill: $dark-gray;
	}


  	.tablet-logo {
		text-align: center;
		img {
			height: 30px;
		}
  }
  @media (min-width: $break-sm) {
    .navbar-collapse.collapse {
      display: none !important;
    }
  }
	.navbar-collapse {
		max-height: none;
		transition-duration: 250ms;
		padding: 0px;
		ul {
			padding-left: 0px;
			> li > a {
				display: block;
				padding: .5em;
				transition: background-color 250ms ease;
				&:hover,
				&:focus {
					text-decoration: none;
				    color: $nav-light-gray;
				    background-color: $background-gray;
				}
			}
		}
		.navbar-content {
			padding: 10px 0px;
			.footer {
				margin-top: 10px;
			}
		}
	}

}

@media (max-width: $break-sm) {
  	.header-container {
      svg {
        width: 1.5em;
        height: 1.5em;
      }
      .utility-toggle {
        padding: 10px;
      }
  	}
}

@media (min-width: $break-md + 1) {
  .utility-nav.expanded {
    display: initial
  }
}

@media (max-width: $break-md) {
  .utility-nav.expanded {
    display: none
  }
}

.nav-tabs {
	border-bottom: none;
	> li {
		border-right: 1px solid $background-gray;
	}
	> li.active {
		margin-bottom: -1px;
	}
	> li > a {
		color: $dark-gray;
		border-radius: 0px;
		margin: 0px;
		border: none;
		background-color: $white;
		border-top: 3px solid transparent;
		padding: 15px 30px;
		transition: border-top 250ms ease, background-color 250ms ease;
		&:hover {
			border-top: 3px solid lighten(rgba($navisphere-orange, 1), 20%);
		}
	}

	> li.active > a,
	> li.active > a:focus,
	> li.active > a:hover {
		border-radius: 0px;
		border: none;
		border-top: 3px solid $navisphere-orange;
		background-color: $background-gray;
		border-right: 1px solid $outline-gray;
		&:hover {
			border-top: 3px solid darken(rgba($navisphere-orange, 1), 20%);
		}
	}
}
