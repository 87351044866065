.icon {
    width: 1.25em;
    height: 1.25em;
    transition: fill 250ms ease;
    position: relative;
    top: .25em;
    fill: $medium-gray;

    &.large {
        width: 2em;
        height: 2em;
    }
    &.medium {
        width: 1.5em;
        height: 1.5em;
    }
    &.small {
        width: 1em;
        height: 1em;
    }
    &.orange {
        fill: $navisphere-orange;
    }
    &.blue {
        fill: $active-blue;
    }
    &.white {
        fill: $white;
    }
    &.error {
    fill: $error;
  }
  &.warning {
    fill: $warning;
  }
  &.success {
    fill: $success;
  }
  &.space-right {
    margin-right: 10px;
  }
  &.space-left {
    margin-left: 10px;
  }
}