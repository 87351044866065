.dropdown-menu {
  min-width: 240px!important;
  margin-top: 0px!important;
  border: none!important;
  border-radius: 0px!important;
  box-shadow: $box-shadow-overlay;
  padding: 1em!important;
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1.1;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 0px;
  }

  li {
    display: block;
    cursor: pointer;
    a,div {
        padding: .5em;
        transition: background-color 250ms ease;
    }
  }
  .dropdown-header,
  .dropdown-footer {
      padding: 0;
      color: #333333;
  }
  .dropdown-footer {
      margin-top: 1em;
  }
  .divider {
      margin: 5px 0px;
  }
}

.user-dropdown-mail-mobile, .user-dropdown-name-mobile {
  color: #ffffff;
}

.dropdown-menu-popup {
  min-width: 100%!important;
  border-radius: 5px!important;
}